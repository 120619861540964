import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Image } from 'mui-image';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { KeyboardKey } from '../../constants/keyboard';
import { getHeadBeast, getSameDates } from '../../helpers/calendar';
import { useKeyPress } from '../../hooks/useKeyDown';
import { configSelector } from '../../redux/modules/config/config.selectors';
import { DayInfo } from '../../types/config';
import { BeastInfo } from '../../utils/calendar';
import { parseJSON } from '../../utils/json';
import { GuideDaysInfoModal } from '../Guide';
import { SoundPlayButton } from '../SoundPlayButton';
import { SoundPlayButtonRef } from '../SoundPlayButton/SoundPlayButton';
import { StyledDialog } from '../StyledDialog';

interface DaysInfoModalProps {
  open: boolean;
  date?: Moment;
  handleClose: () => void;
}

const EMPTY_DAY_INFO = {
  id: 0,
  beastNumber: 0,
  title: '',
  shortDescription: '',
  description: '',
  colorId: 0,
  beastId: 0,
  dates: [],
};

type DayInfoWithBeast = DayInfo & BeastInfo & { dates: Moment[] };

export function DaysInfoModal(props: DaysInfoModalProps) {
  const [currentDayInfo, setCurrentDayInfo] = useState<DayInfoWithBeast>(EMPTY_DAY_INFO);
  const [currentDate, setCurrentDate] = useState(props.date || moment());
  const { colors, fontColors, beasts, numberBeasts, dateNull: sysDateNull, daysInfo } = useSelector(configSelector);
  const soundPlayButtonRef = useRef<SoundPlayButtonRef>(null);
  const [fullScreen, setFullScreen] = useState(parseJSON(localStorage.getItem('daysInfoModalFullScreen') || 'false'));
  const [fileToPlay, setFileToPlay] = useState<string>();

  const color = colors[currentDayInfo.colorId];

  const canPlay = (): boolean => Boolean(fileToPlay) && currentDayInfo?.beastNumber <= 60;

  const onendSound = () => {
    if (fileToPlay?.endsWith('/short-description.m4a')) {
      setFileToPlay(`/sounds/beasts/${currentDayInfo.beastNumber}/title.m4a`);
    } else if (fileToPlay?.endsWith('/title.m4a')) {
      setFileToPlay(`/sounds/beasts/${currentDayInfo.beastNumber}/description.m4a`);
    } else {
      setFileToPlay(`/sounds/beasts/${currentDayInfo.beastNumber}/short-description.m4a`);
    }
  };

  const handlePlay = () => {
    if (soundPlayButtonRef?.current?.isPlaying()) {
      soundPlayButtonRef?.current?.pause();
    } else if (canPlay()) {
      setTimeout(() => {
        soundPlayButtonRef?.current?.play();
      }, 100);
    }
  };

  useEffect(() => {
    if (fileToPlay && !fileToPlay?.endsWith('/short-description.m4a')) {
      setTimeout(() => {
        soundPlayButtonRef?.current?.play();
      }, 100);
    } else if (fileToPlay?.endsWith('/short-description.m4a')) {
      soundPlayButtonRef?.current?.stop();
    }
  }, [fileToPlay]);

  const calculateDayInfo = (date = currentDate) => {
    const headBeast = getHeadBeast(numberBeasts, date, sysDateNull);
    const dayInfo = _.find(daysInfo, { beastNumber: headBeast.beastNumber });
    const dates = getSameDates(date);

    setCurrentDayInfo({
      ...(dayInfo || EMPTY_DAY_INFO),
      ...headBeast,
      dates,
    });

    if (headBeast.beastNumber) {
      setFileToPlay(`/sounds/beasts/${headBeast.beastNumber}/short-description.m4a`);
    }
  };

  const changeDay = (dx: number) => setCurrentDate(currentDate.clone().add(dx, 'days'));

  useKeyPress((key) => {
    if ([KeyboardKey.ArrowLeft, KeyboardKey.ArrowRight].includes(key as KeyboardKey)) {
      changeDay(key === KeyboardKey.ArrowLeft ? -1 : 1);
    } else if ([KeyboardKey.ArrowDown, KeyboardKey.ArrowUp].includes(key as KeyboardKey)) {
      changeDay(key === KeyboardKey.ArrowUp ? -60 : 60);
    } else if ([KeyboardKey.Enter].includes(key as KeyboardKey)) {
      handlePlay();
    }
  }, [KeyboardKey.ArrowLeft, KeyboardKey.ArrowRight, KeyboardKey.ArrowUp, KeyboardKey.ArrowDown, KeyboardKey.Enter]);

  useEffect(() => {
    if (props.open) {
      if (!props.date) {
        const date = moment();
        setCurrentDate(date);
      }

      setTimeout(() => {
        const section = document.querySelector('#current-day-info-description');

        if (!section) {
          return;
        }

        section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 1500);
    } else {
      soundPlayButtonRef?.current?.pause();
    }
  }, [props.open]);

  useEffect(() => {
    if (props.date) {
      setCurrentDate(props.date);
    }
  }, [props.date]);

  useEffect(() => {
    calculateDayInfo(currentDate);
  }, [currentDate]);

  useEffect(() => {
    localStorage.setItem('daysInfoModalFullScreen', fullScreen);
  }, [fullScreen]);

  return (
    <StyledDialog
      onClose={props.handleClose}
      open={props.open}
      color={color}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container flexDirection="row" alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="relative"
            xs={4}
            sm={3}
            sx={{
              // backgroundColor: 'white',
              color: fontColors[color],
              borderRadius: 5,
              border: '1px solid',
              display: 'flex',
            }}
          >
            {canPlay() && (
              <Grid position="absolute" sx={{ top: 0, right: 0 }}>
                <GuideDaysInfoModal />
                <SoundPlayButton
                  ref={soundPlayButtonRef}
                  src={fileToPlay!}
                  options={{ onend: onendSound, volume: 1 }}
                />
              </Grid>
            )}
            <Grid item fontSize="x-large">
              {currentDayInfo.beastNumber}
            </Grid>
            <Grid item fontSize="large">
              {beasts[currentDayInfo.beastId]}
            </Grid>
          </Grid>
          <Grid item xs={8} sm={9} sx={{ pl: 2 }}>
            <Typography variant="h6" textAlign="left" sx={{ color: fontColors[color] }}>
              {currentDayInfo.title}
            </Typography>
            <Typography variant="subtitle2" textAlign="left" sx={{ color: fontColors[color] }}>
              {moment(currentDate).format('dddd, MMMM DD, YYYY')}
            </Typography>
          </Grid>
          <IconButton
            color="primary"
            aria-label="full screen"
            data-html2canvas-ignore="true"
            component="label"
            sx={{ position: 'absolute', top: 30, right: 0 }}
            onClick={(e) => {
              if ((e as any)?.code === KeyboardKey.Enter) {
                return;
              }

              setFullScreen((fs) => !fs);
            }}
          >
            {fullScreen ? (<FullscreenExitIcon />) : (<FullscreenIcon />)}
          </IconButton>
          <IconButton
            color="primary"
            aria-label="close"
            data-html2canvas-ignore="true"
            component="label"
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item container flexDirection="row" xs={12} md={3}>
            <Image
              src={`/images/beasts/${currentDayInfo.beastNumber}.jpg`}
              fit="contain"
              alt="capture"
              duration={1500}
              style={{
                borderRadius: '7px',
                maxWidth: '178px',
                maxHeight: '300px',
              }}
            />
          </Grid>
          <Grid item container flexDirection="row" xs={12} md={6} id="current-day-info-description">
            <Typography variant="subtitle2" textAlign="center" sx={{ color: fontColors[color] }}>
              {currentDayInfo.description}
            </Typography>
          </Grid>
          <Grid item container md={2} display={{ xs: 'none', md: 'block' }}>
            {_.map(currentDayInfo.dates, (value, key) => {
              const isSameDay = value.isSame(currentDate, 'day');

              return (
                <Grid
                  item
                  key={key}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    p: 1,
                    color: fontColors[color],
                    ...(isSameDay ? { border: '2px dashed purple', borderRadius: 2.5 } : {}),
                  }}
                >
                  <span>{moment(value).format('DD.MM.YYYY')}</span>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id="day_info_prev_button" variant="contained" color="primary" onClick={() => changeDay(-1)}>
          <ArrowBackIcon />
        </Button>

        <Grid container justifyContent="center">
          <Button color="warning" size="small">
            Ошибка в тексте
          </Button>
        </Grid>

        <Button id="day_info_next_button" variant="contained" color="primary" onClick={() => changeDay(1)}>
          <ArrowForwardIcon />
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
