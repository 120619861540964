import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import ruLocale from 'date-fns/locale/ru';
import { cacheLtr, lightTheme, darkTheme, ColorModeContext } from '../../helpers/theme';
import { AppInnerRouter } from './AppInnerRouter';
import 'moment/locale/ru';
import './reset.scss';

moment().locale('ru');

type ThemeMode = 'light' | 'dark';

export function AppView() {
  const [mode, setMode] = React.useState<ThemeMode>(localStorage.getItem('theme') as ThemeMode);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => setMode(e.matches ? 'dark' : 'light'));

    if (!mode) {
      setMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  // eslint-disable-next-line no-confusing-arrow
  const theme = React.useMemo(() => mode === 'light' ? lightTheme : darkTheme, [mode]);

  return (
    <CacheProvider value={cacheLtr}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <BrowserRouter>
              <AppInnerRouter />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CacheProvider>
  );
}
