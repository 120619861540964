import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { CreateArticleResponse, DeleteArticleResponse, FetchArticlesResponse } from '../../../types/article';
import { cleanArticle, createArticle, deleteArticle, fetchArticles } from './article.actions';
import { ArticleState } from './article.types';

const initialState: ArticleState = {
  fetchStatus: fetchStatuses.idle,
  addStatus: fetchStatuses.idle,
  articles: [],
  error: null,
};

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchArticles.fulfilled, (state, action: PayloadAction<FetchArticlesResponse>) => {
      for (const item of action.payload.data) {
        if (state.articles.findIndex((bt) => bt.id === item.id) < 0) {
          state.articles.push(item);
        }
      }
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchArticles.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createArticle.pending, (state, action) => {
      state.articles.push(action.meta.arg);
      state.addStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(createArticle.fulfilled, (state, action: PayloadAction<CreateArticleResponse>) => {
      state.articles = state.articles.map((bt) => {
        if (bt.id === (action as any)?.meta?.arg?.id) {
          return action.payload.data;
        }

        return bt;
      });
      state.addStatus = fetchStatuses.success;
    });
    builder.addCase(createArticle.rejected, (state, action) => {
      state.addStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteArticle.pending, (state, action) => {
      state.articles = state.articles.filter((bt) => bt.id !== action.meta.arg);
      state.addStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(deleteArticle.fulfilled, (state, action: PayloadAction<DeleteArticleResponse>) => {
      state.articles = state.articles.filter((bt) => bt.id !== action.payload.data.id);
      state.addStatus = fetchStatuses.success;
    });
    builder.addCase(deleteArticle.rejected, (state, action) => {
      state.addStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanArticle.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const articleReducer = articleSlice.reducer;
