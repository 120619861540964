export const toQueryParams = (data: { [prop: string]: any }): string => {
  const values: Record<string, string> = {};

  for (const dataKey in data) {
    if (data[dataKey] !== undefined && data[dataKey] !== null) {
      values[dataKey] = String(data[dataKey]);
    }
  }

  return new URLSearchParams(values).toString();
};
