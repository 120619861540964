import { useRef, useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { parseJSON } from '../../utils/json';

export const GuideDaysInfoModal = () => {
  const [run, setRun] = useState<boolean>(!parseJSON(localStorage.getItem('guide-days-info-modal') || 'false'));

  const steps = useRef<Step[]>([
    {
      target: '#pay_days_info_button',
      content: 'Прослушать описание на день (Кнопка Enter на клавиатуре)',
      placement: 'bottom',
    },
    {
      target: '#day_info_prev_button',
      content: 'Описание на предыдущий день (Копка ← на клавиатуре)',
      placement: 'top',
    },
    {
      target: '#day_info_next_button',
      content: 'Описание на следующий день (Копка → на клавиатуре)',
      placement: 'top',
    },
  ]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(data.status)) {
      localStorage.setItem('guide-days-info-modal', 'true');
      setRun(false);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      locale={{
        back: 'Назад',
        close: 'Закрыть',
        last: 'Завершить',
        next: 'Следующий',
        open: 'Открыть описание',
        skip: 'Пропустить',
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
      steps={steps.current}
    />
  );
};
