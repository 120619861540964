export enum loginFormFields {
  email = 'email',
  password = 'password',
}

export enum signupFormFields {
  name = 'name',
  email = 'email',
  password = 'password',
  dob = 'dob',
}

export enum forgotPasswordFormFields {
  email = 'email',
}

export enum restorePasswordFormFields {
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  token = 'token',
}
