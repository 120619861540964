import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { Image } from 'mui-image';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import { isSelectedItem } from './helpers';
import {
  CustomListItemButton,
  Drawer as CustomDrawer,
  DrawerHeader,
} from './SidebarElements';
import { MenuItems, MenuItemSimple } from './types';
import packageJson from '../../../../package.json';

interface ISidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  menuItems: MenuItems;
  onlyMobile?: boolean;
}

export const Sidebar = ({ open, handleDrawerClose, menuItems, onlyMobile }: ISidebarProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');
  const isDark = theme.palette.mode === 'dark';

  const onClick = (menuItem: MenuItemSimple) => {
    if ('path' in menuItem) {
      navigate(menuItem.path);
      if (!matchMdAndAboveView) handleDrawerClose();
      return;
    }
    if (!matchMdAndAboveView) handleDrawerClose();
    menuItem.onClick();
  };

  const renderSimpleMenuItem = (menuItem: MenuItemSimple, index: number) => {
    const isSelected = isSelectedItem(menuItem, pathname);

    return (
      <CustomListItemButton
        selected={isSelected}
        onClick={() => onClick(menuItem)}
        key={`menu-item-${index}`}
        sx={{
          height: '40px',
          justifyContent: open ? 'initial' : 'center',
          borderRadius: '4px',
          mb: '18px',
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? '14px' : 'auto',
            width: '20px',
            height: '20px',
            justifyContent: 'center',
          }}
        >
          <menuItem.IconComponent />
        </ListItemIcon>
        <ListItemText primary={menuItem.title} sx={{ opacity: open ? 1 : 0 }} />
      </CustomListItemButton>
    );
  };

  const renderContent = () => (
    <>
      <DrawerHeader>
        <Image
          style={{ padding: '5px', filter: isDark ? 'invert(100%)' : 'unset' }}
          src="/logo.png"
          fit="contain"
          alt="logo"
          duration={300}
        />
      </DrawerHeader>
      <Divider />
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{ px: '32px', height: '100%', mt: 5 }}
      >
        {menuItems.map((items, listIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item container key={`main-menu-item-${listIndex}`}>
            <List component="div" disablePadding sx={{ width: '100%' }}>
              {items.map(renderSimpleMenuItem)}
            </List>
          </Grid>
        ))}
      </Grid>
      <Box
        display="none"
        alignItems="center"
        justifyContent="center"
        marginTop="auto"
      >
        <Typography variant="body2">
          {open ? 'Version:' : ''} {packageJson.version}
        </Typography>
      </Box>
    </>
  );

  return matchMdAndAboveView
    ? (
      <CustomDrawer
        variant="permanent"
        open={open}
        elevation={0}
        sx={onlyMobile ? { display: { xs: 'block', sm: 'none' } } : {}}
      >
        {renderContent()}
      </CustomDrawer>
    )
    : (
      <Drawer open={open} anchor="left" onClose={handleDrawerClose}>
        {renderContent()}
      </Drawer>
    );
};
