import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import AppPageLayout from '../layouts/AppPageLayout';
import PublicPageLayout from '../layouts/PublicPageLayout';
import { About } from '../pages/About';
import { CalendarGrid } from '../pages/CalendarGrid';
import { Dashboard } from '../pages/Dashboard';
import { Error } from '../pages/Error';
import { ForgotPassword } from '../pages/ForgotPassword';
import { History } from '../pages/History';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { PersonalCalendar } from '../pages/PersonalCalendar';
import { Signup } from '../pages/Signup';
import { User } from '../pages/User';
import { UserList } from '../pages/UserList';
import { authSelector } from '../redux/modules/auth/auth.selectors';
import { locations } from './locations';

export function AppRoutes() {
  const { pathname, hash, key, state } = useLocation();
  const { isLoggedIn } = useSelector(authSelector);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      // else scroll to id
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const loggedInBasedHomeRoutes: RouteObject[] = [];

  const loggedInBasedAppRoutes: RouteObject[] = [
    {
      path: locations.personalCalendar(),
      element: <PersonalCalendar />,
    },
    {
      path: locations.calendarGrid(),
      element: <CalendarGrid />,
    },
    {
      path: locations.about(),
      element: <About />,
    },
  ];

  return useRoutes([
    {
      path: locations.home(),
      element: <PublicPageLayout />,
      children: [
        {
          index: true,
          path: locations.home(),
          element: <Home />,
        },
        {
          path: locations.login(),
          element: <Login />,
        },
        ...(isLoggedIn ? [] : loggedInBasedHomeRoutes),
        ...(isLoggedIn ? [] : loggedInBasedAppRoutes),
        {
          path: locations.forgotPassword(),
          element: <ForgotPassword />,
        },
        {
          path: locations.signUp(),
          element: <Signup />,
        },
        {
          path: locations.notFound(),
          element: <Error />,
        },
        {
          path: '*',
          element: <Navigate to={locations.notFound()} />,
        },
      ],
    },
    {
      path: locations.home(),
      element: <AppPageLayout />,
      children: [
        {
          index: true,
          path: locations.dashboard(),
          element: <Dashboard />,
        },
        {
          path: locations.history(),
          element: <History />,
        },
        {
          path: locations.userList(),
          element: <Outlet />,
          children: [
            {
              index: true,
              path: locations.userList(),
              element: <UserList />,
            },
            {
              path: locations.user(),
              element: <User />,
            },
          ],
        },
        ...(isLoggedIn ? loggedInBasedAppRoutes : []),
        {
          path: '*',
          element: <Navigate to={locations.notFound()} />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={locations.home()} replace state={state} />,
    },
  ]);
}
