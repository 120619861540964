import * as yup from 'yup';
import '../common/extends';
import { TreeType } from '../../../types/tree';
import { createTreeFormFields } from '../../fields/formFields';

export const createTreeFormSchema = yup.object({
  [createTreeFormFields.treeType]: yup.mixed<TreeType>().oneOf(Object.values(TreeType)).required(),
  [createTreeFormFields.title]: yup.string().trim().required('Название обязательно'),
  [createTreeFormFields.date]: yup.date().nullable().required('Дата обязательна'),
  [createTreeFormFields.titleNull]: yup.string()
    .when(createTreeFormFields.treeType, {
      is: TreeType.Projection,
      then: yup.string().trim().required('Название обязательно'),
    }),
  [createTreeFormFields.dateNull]: yup.date().nullable()
    .when(createTreeFormFields.treeType, {
      is: TreeType.Projection,
      then: yup.date().nullable().required('Дата обязательна'),
    }),
});
