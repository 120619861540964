import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../../../api';
import { LoginRequest, ForgotPasswordRequest, RestorePasswordRequest, SignupRequest } from '../../../types/auth';

export const login = createAsyncThunk('auth/login', (data: LoginRequest) => authService.login(data));

export const signup = createAsyncThunk('auth/signup', (data: SignupRequest) => authService.signup(data));

export const logout = createAsyncThunk('auth/logout', () => authService.logout());

export const fetchUserProfile = createAsyncThunk('auth/profile', () => authService.fetchUserProfile());

export const forgotPassword = createAsyncThunk('auth/forgot-password', (data: ForgotPasswordRequest) => authService.forgotPassword(data));

export const restorePassword = createAsyncThunk('auth/restore-password', (data: RestorePasswordRequest) => authService.restorePassword(data));

export const cleanAuth = createAction('auth/clean');
