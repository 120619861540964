import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { DayInfo } from '../../../types/config';
import { fetchDaysInfo } from './config.actions';
import { ConfigState } from './config.types';

const initialState: ConfigState = {
  beasts: ['Крыса', 'Волк', 'Тигр', 'Кот', 'Дракон', 'Змея', 'Лошадь', 'Овца', 'Обезьяна', 'Петух', 'Собака', 'Кабан'],
  colors: ['aqua', 'aqua', 'red', 'red', 'yellow', 'yellow', 'white', 'white', 'gray', 'gray'],
  fontColors: {
    aqua: 'black',
    red: 'black',
    yellow: 'black',
    white: 'black',
    gray: 'white',
  },
  dateNull: '1984-01-01',
  numberBeasts: [
    [1, 13, 25, 37, 49],
    [2, 14, 26, 38, 50],
    [51, 3, 15, 27, 39],
    [52, 4, 16, 28, 40],
    [41, 53, 5, 17, 29],
    [42, 54, 6, 18, 30],
    [31, 43, 55, 7, 19],
    [32, 44, 56, 8, 20],
    [21, 33, 45, 57, 9],
    [22, 34, 46, 58, 10],
    [11, 23, 35, 47, 59],
    [12, 24, 36, 48, 60],
  ],

  daysInfo: [],
  fetchStatus: fetchStatuses.idle,

  error: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDaysInfo.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchDaysInfo.fulfilled, (state, action: PayloadAction<{ data: DayInfo[] }>) => {
      state.daysInfo = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchDaysInfo.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const configReducer = configSlice.reducer;
