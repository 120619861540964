import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCaptureImageOpen } from '../../redux/modules/captureImage/captureImage.actions';
import { captureImageSelector } from '../../redux/modules/captureImage/captureImage.selectors';
import { CaptureImageDialog } from './CaptureImageDialog';

export const GlobalCaptureImageController = () => {
  const dispatch = useDispatch();

  const { fileName, imageUrl, open } = useSelector(captureImageSelector);

  const closeDialog = () => {
    dispatch(setCaptureImageOpen(false));
  };

  return (
    <CaptureImageDialog
      fileName={fileName}
      imageUrl={imageUrl}
      open={open}
      handleClose={closeDialog}
    />
  );
};
