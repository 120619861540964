import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener, Collapse } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { configSelector } from '../../redux/modules/config/config.selectors';
import { IBoardProjectionTree, UpdateBoardTreePayload } from '../../types/tree';
import { Biorhythms } from '../Biorhythms';
import { HtmlTooltip } from '../Tooltip';
import { Tree } from '../Tree';

// TODO: Swiper to delete or to use
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './projectionTree.css';

interface ProjectionTreeProps {
  tree: IBoardProjectionTree;
  updateTreeData: (data: UpdateBoardTreePayload) => void;
}

export const ProjectionTree = (props: ProjectionTreeProps) => {
  const { tree, updateTreeData } = props;
  const [showPickDays, setShowPickDays] = React.useState(false);
  const [liveDays, setLiveDays] = React.useState(0);
  const [beforeAfterDays, setBeforeAfterDays] = React.useState(0);
  const [openAdditionalTooltip, setOpenAdditionalTooltip] = React.useState(false);

  const handleCloseAdditionalInfoTooltip = () => {
    setOpenAdditionalTooltip(false);
  };

  const handleOpenAdditionalInfoTooltip = () => {
    setOpenAdditionalTooltip(true);
  };

  const { dateNull: sysDateNull } = useSelector(configSelector);

  useEffect(() => {
    const toDate = moment(tree.date);
    const fromDate = moment(tree.dateNull);
    const sysDate = moment(sysDateNull);

    if (toDate.isValid() && fromDate.isValid()) {
      setLiveDays(toDate.diff(fromDate, 'day'));
      setBeforeAfterDays(toDate.diff(sysDate, 'day'));
    }
  }, [tree.date, tree.dateNull]);

  return (
    <>
      <IconButton
        color="warning"
        aria-label="toggle pick days view"
        className="tree__controller__pick-days-btn"
        data-html2canvas-ignore="true"
        component="label"
        onClick={() => setShowPickDays((prev) => !prev)}
      >
        <VisibilityIcon />
      </IconButton>
      <Grid
        item
        position="absolute"
        sx={{
          top: tree.canDelete ? '26px' : '-5px',
          right: '-5px',
        }}
      >
        <ClickAwayListener onClickAway={handleCloseAdditionalInfoTooltip}>
          <Grid>
            <HtmlTooltip
              open={openAdditionalTooltip}
              onClose={handleCloseAdditionalInfoTooltip}
              onOpen={handleOpenAdditionalInfoTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <React.Fragment>
                  <Typography color="inherit">Доп. Информация</Typography>
                  <em>Дата сравнения:</em> <b>{moment(sysDateNull).format('DD.MM.YYYY')}</b>.<br />
                  <em>Дней прошло:</em> <b>{liveDays}</b>.<br />
                  <em>Дней до/после:</em> <b>{beforeAfterDays}</b>.<br />
                  ...
                </React.Fragment>
              }
            >
              <IconButton
                color="primary"
                aria-label="toggle additional info"
                className="tree__controller__info-btn"
                component="label"
                data-html2canvas-ignore="true"
                onClick={handleOpenAdditionalInfoTooltip}
              >
                <InfoIcon />
              </IconButton>
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      </Grid>
      <Grid item container spacing={4} xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={6} md={6}>
          <Tree
            projection
            date={tree.dateNull}
            title={tree.titleNull}
            setDate={(newDate) => updateTreeData({ id: tree.id, dateNull: newDate })}
            setTitle={(newTitle) => updateTreeData({ id: tree.id, titleNull: newTitle })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Tree
            projection
            date={tree.date}
            dateNull={tree.dateNull}
            title={tree.title}
            setDate={(newDate) => updateTreeData({ id: tree.id, date: newDate })}
            setTitle={(newTitle) => updateTreeData({ id: tree.id, title: newTitle })}
          />
        </Grid>
      </Grid>
      <Collapse in={showPickDays} sx={{ width: '100%' }}>
        <Paper elevation={0}>
          <Divider variant="middle" sx={{ mt: 4 }} />
          <Biorhythms date={tree.date} autoUpdate={showPickDays} liveDays={liveDays} />
        </Paper>
      </Collapse>
    </>
  );
};
