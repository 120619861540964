import { Link, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

export function Copyright(props: TypographyProps) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://trin-calendar.ru">
        Ivan Sarokin
      </Link>{' '}
      2019 - {new Date().getFullYear()}.
      Все права защищены.
    </Typography>
  );
}
