import { useTheme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { calculateDay } from '../../helpers/calendar';
import { configSelector } from '../../redux/modules/config/config.selectors';
import { InlineEdit } from '../InlineEdit';
import { ITreeElements, ITreeElement } from '../../types/tree';

import './tree.css';

// import SaveTreeCharacteristicsModal from './SaveTreeCharacteristicsModal';
// import { delTree } from '../actions/TreeActions';

const firstItem: ITreeElement = { beastId: 0, colorId: 0, number: 1 };

interface TreeProps {
  projection?: boolean;
  title: string;
  date: string;
  dateNull?: string;
  readOnly?: boolean;
  setTitle: (newTitle: string) => void;
  setDate: (newDate: string) => void;
}

export const Tree = (props: TreeProps) => {
  const theme = useTheme();
  const { colors, fontColors, beasts, numberBeasts, daysInfo, ...config } = useSelector(configSelector);
  const title = props.title.length > 15 ? `${props.title.substring(0, 15)}...` : props.title;
  const isDark = theme.palette.mode === 'dark';
  const readOnly = props.readOnly ?? false;

  const [date, setDate] = useState<string>(props.date);
  const [dateValue, setDateValue] = useState<Date | null>(new Date(props.date));

  const [tree, setTree] = useState<ITreeElements>({
    date,
    title,
    head: firstItem,
    body: firstItem,
    feet: firstItem,
    reliquary: firstItem,
    topBelt: firstItem,
    botBelt: firstItem,
    leftTopGuard: firstItem,
    leftBotGuard: firstItem,
    rightTopGuard: firstItem,
    rightBotGuard: firstItem,
  });

  const calculate = () => {
    const allDay = calculateDay({ date, configDateNull: config.dateNull, dateNull: props.dateNull, numberBeasts });

    setTree({
      ...allDay,
      title,
    });
  };

  const onDateChange = (newDate: Date | null) => {
    setDateValue(newDate);

    if (newDate && moment(newDate).isValid()) {
      setDate(moment(newDate).format('YYYY-MM-DD'));
      props.setDate(moment(newDate).format('YYYY-MM-DD'));
    }
  };

  const getBeastTooltip = (beastNumber: number) => daysInfo[beastNumber === 0 ? 59 : beastNumber - 1]?.shortDescription || '';

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  useEffect(() => {
    calculate();
  }, [date, props.dateNull]);

  const renderTextField = (params: TextFieldProps) => (
    <TextField
      {...params}
      fullWidth
      margin="none"
      // inputProps={{ ...params.inputProps, readOnly: true }}
      sx={{ '.MuiInputBase-input': { padding: 2 } }}
    />
  );

  // eslint-disable-next-line prefer-template
  const className = 'tree' +
    (isDark ? ' tree--dark' : '') +
    (props.projection ? ' tree--projection' : '');

  return (
    <Grid container className={className} justifyContent="center">
      <Grid item className="tree__header" sx={{ mx: 'auto', mb: 3 }}>
        <InlineEdit value={title} setValue={props.setTitle} />
      </Grid>

      <Grid item className="tree__date" sx={{ mb: 3, width: '150px' }}>
        {!readOnly && (
          <DatePicker
            value={dateValue}
            onChange={onDateChange}
            inputFormat="dd.MM.yyyy"
            renderInput={renderTextField}
          />
        )}
      </Grid>

      <Grid item className="tree__line">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.head.number)}>
          <Grid
            item
            className="tree__line__node tree__line__node--main"
            xs={6}
            md={6}
            sx={{ backgroundColor: colors[tree.head.colorId], color: fontColors[colors[tree.head.colorId]] }}
          >
            <strong>{beasts[tree.head.beastId]} </strong>
            {tree.head.number ? tree.head.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item className="tree__line">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.topBelt.number)}>
          <Grid
            item
            className="tree__line__node tree__line__node--belt"
            xs={8}
            md={8}
            sx={{ backgroundColor: colors[tree.topBelt.colorId], color: fontColors[colors[tree.topBelt.colorId]] }}
          >
            <strong>{beasts[tree.topBelt.beastId]} </strong>
            {tree.topBelt.number ? tree.topBelt.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item container className="tree__line tree__line--multiple">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.leftTopGuard.number)}>
          <Grid
            item
            className="tree__line__node"
            xs={5}
            md={5}
            sx={{
              backgroundColor: colors[tree.leftTopGuard.colorId],
              color: fontColors[colors[tree.leftTopGuard.colorId]],
            }}
          >
            <strong>{beasts[tree.leftTopGuard.beastId]} </strong>
            {tree.leftTopGuard.number ? tree.leftTopGuard.number : 60}
          </Grid>
        </Tooltip>

        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.rightTopGuard.number)}>
          <Grid
            item
            className="tree__line__node"
            xs={5}
            md={5}
            sx={{
              backgroundColor: colors[tree.rightTopGuard.colorId],
              color: fontColors[colors[tree.rightTopGuard.colorId]],
            }}
          >
            <strong>{beasts[tree.rightTopGuard.beastId]} </strong>
            {tree.rightTopGuard.number ? tree.rightTopGuard.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item className="tree__line">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.body.number)}>
          <Grid
            item
            className="tree__line__node tree__line__node--main"
            xs={6}
            md={6}
            sx={{ backgroundColor: colors[tree.body.colorId], color: fontColors[colors[tree.body.colorId]] }}
          >
            <strong>{beasts[tree.body.beastId]} </strong>
            {tree.body.number ? tree.body.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item container className="tree__line tree__line--multiple">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.leftBotGuard.number)}>
          <Grid
            item
            className="tree__line__node"
            xs={5}
            md={5}
            sx={{
              backgroundColor: colors[tree.leftBotGuard.colorId],
              color: fontColors[colors[tree.leftBotGuard.colorId]],
            }}
          >
            <strong>{beasts[tree.leftBotGuard.beastId]} </strong>
            {tree.leftBotGuard.number ? tree.leftBotGuard.number : 60}
          </Grid>
        </Tooltip>

        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.rightBotGuard.number)}>
          <Grid
            item
            className="tree__line__node"
            xs={5}
            md={5}
            sx={{
              backgroundColor: colors[tree.rightBotGuard.colorId],
              color: fontColors[colors[tree.rightBotGuard.colorId]],
            }}
          >
            <strong>{beasts[tree.rightBotGuard.beastId]} </strong>
            {tree.rightBotGuard.number ? tree.rightBotGuard.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item className="tree__line">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.botBelt.number)}>
          <Grid
            item
            className="tree__line__node tree__line__node--belt"
            xs={8}
            md={8}
            sx={{ backgroundColor: colors[tree.botBelt.colorId], color: fontColors[colors[tree.botBelt.colorId]] }}
          >
            <strong>{beasts[tree.botBelt.beastId]} </strong>
            {tree.botBelt.number ? tree.botBelt.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item className="tree__line">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.feet.number)}>
          <Grid
            item
            className="tree__line__node tree__line__node--main"
            xs={6}
            md={6}
            sx={{ backgroundColor: colors[tree.feet.colorId], color: fontColors[colors[tree.feet.colorId]] }}
          >
            <strong>{beasts[tree.feet.beastId]} </strong>
            {tree.feet.number ? tree.feet.number : 60}
          </Grid>
        </Tooltip>
      </Grid>

      <Grid item className="tree__line tree__line__node--reliquary">
        <Tooltip enterTouchDelay={0} title={getBeastTooltip(tree.reliquary.number)}>
          <Grid
            item
            className="tree__line__node"
            xs={4}
            md={4}
            sx={{ backgroundColor: colors[tree.reliquary.colorId], color: fontColors[colors[tree.reliquary.colorId]] }}
          >
            <strong>{beasts[tree.reliquary.beastId]} </strong>
            {tree.reliquary.number ? tree.reliquary.number : 60}
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
