import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import Typography from '@mui/material/Typography';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import useSound from 'use-sound';

interface LogoTextProps {
  isMobile?: boolean;
}

export const LogoText = ({ isMobile }: LogoTextProps) => {
  const [playWelcome] = useSound(
    '/sounds/welcome.m4a',
    { volume: 0.25, interrupt: true },
  );

  return (
    <Typography
      variant="h6"
      component="div"
      sx={{
        flexGrow: 1,
        display: 'flex',
        fontSize: isMobile ? '13px' : '18px',
        alignItems: 'center',
        flexDirection: 'center',
      }}
    >
      <IconButton
        color="primary"
        aria-label="Добро пожаловать"
        component="span"
        onClick={() => playWelcome()}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <VolumeUpIcon />
      </IconButton>
      <TypeAnimation
        sequence={[
          'ТРИНОМЕТРИЧЕСКИЙ',
          1000,
          'ТРИНОМЕТРИЧЕСКИЙ КАЛЕНДАРЬ',
          10000,
          '',
          1000,
        ]}
        cursor={false}
        speed={20}
        deletionSpeed={10}
        repeat={Infinity}
      />
    </Typography>
  );
};
