import { Button, FormControlLabel, Radio } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';
import { IAsyncDialogProps } from '../../components/AsyncDialog/AsyncDialog';
import { createTreeFormFields } from '../../forms/fields/formFields';
import { createTreeFormSchema } from '../../forms/validationSchema/formSchema';
import { BoardTree, TreeType } from '../../types/tree';

export function CreateTreeDialog(props: IAsyncDialogProps<BoardTree>) {
  const inputRef = useRef<HTMLInputElement>();

  const { handleSubmit, values, touched, errors, setFieldValue, resetForm, getFieldProps } = useFormik({
    initialValues: {
      [createTreeFormFields.treeType]: TreeType.Simple,
      [createTreeFormFields.title]: '',
      [createTreeFormFields.date]: new Date(),
      [createTreeFormFields.titleNull]: '',
      [createTreeFormFields.dateNull]: new Date(),
    },
    validationSchema: createTreeFormSchema,
    onSubmit: (formValues) => {
      const payload = {
        id: `user-board-tree-${Date.now()}`,
        type: formValues[createTreeFormFields.treeType],
        canDelete: true,
        ...formValues[createTreeFormFields.treeType] === TreeType.Simple ? {
          title: formValues[createTreeFormFields.title],
          date: moment(formValues[createTreeFormFields.date]).format('YYYY-MM-DD'),
        } : {
          titleNull: formValues[createTreeFormFields.title],
          dateNull: moment(formValues[createTreeFormFields.date]).format('YYYY-MM-DD'),
          title: formValues[createTreeFormFields.titleNull],
          date: moment(formValues[createTreeFormFields.dateNull]).format('YYYY-MM-DD'),
        },
      };
      return props.onOk(payload as BoardTree);
    },
  });

  useEffect(() => {
    if (!props.open) {
      resetForm({
        values: {
          treeType: TreeType.Simple,
          title: '',
          date: new Date(),
          titleNull: '',
          dateNull: new Date(),
        },
      });
    } else {
      setTimeout(() => inputRef?.current?.focus(), 0);
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Добавить новое дерево</DialogTitle>
      <DialogContent>
        <Grid container flexDirection="column" component="form">
          <Grid item>
            <FormControl>
              <FormLabel id="tree-type-group-label">Тип Дерева</FormLabel>
              <RadioGroup
                row
                aria-labelledby="tree-type-group-label"
                {...getFieldProps(createTreeFormFields.treeType)}
              >
                <FormControlLabel value={TreeType.Simple} control={<Radio />} label="Обычное" />
                <FormControlLabel value={TreeType.Projection} control={<Radio />} label="Проекция" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              inputRef={inputRef}
              margin="normal"
              label="Название"
              placeholder="Введите название"
              variant="standard"
              type="text"
              {...getFieldProps(createTreeFormFields.title)}
              error={touched.title && !!errors.title}
              helperText="Название обязательно"
              autoFocus
              fullWidth
              required
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Дата"
              value={values[createTreeFormFields.date]}
              onChange={(newValue) => {
                setFieldValue(createTreeFormFields.date, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={touched.date && !!errors.date}
                  helperText="Дата обязательна"
                />
              )}
            />
          </Grid>
          {values.treeType === TreeType.Projection && (
            <>
              <Grid item>
                Проекция
              </Grid>
              <Grid item>
                <TextField
                  margin="normal"
                  label="Название"
                  placeholder="Введите название"
                  variant="standard"
                  type="text"
                  {...getFieldProps(createTreeFormFields.titleNull)}
                  error={touched.titleNull && !!errors.titleNull}
                  helperText="Название обязательно"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="Дата"
                  value={values[createTreeFormFields.dateNull]}
                  onChange={(newValue) => {
                    setFieldValue(createTreeFormFields.dateNull, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={touched.dateNull && !!errors.dateNull}
                      helperText="Дата обязательна"
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={props.onClose}>Отмена</Button>
        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Создать</Button>
      </DialogActions>
    </Dialog>
  );
}
