import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchUserResponse,
  FetchUsersResponse,
} from '../../../types/user';
import {
  cleanUsers,
  createUser,
  fetchUser,
  fetchUsers,
  resetEditUser,
  updateUser,
} from './user.actions';
import { UserState } from './user.types';

const initialState: UserState = {
  users: [],
  totalUsers: 0,
  editUser: null,
  fetchStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,

  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<FetchUsersResponse>) => {
      state.users = action.payload.data?.items;
      state.totalUsers = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUser.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<FetchUserResponse>) => {
      state.editUser = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUser.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createUser.fulfilled, (state, action: PayloadAction<FetchUserResponse>) => {
      state.editUser = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUser.type, (state) => {
      state.editUser = null;
    });

    builder.addCase(cleanUsers.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userReducer = usersSlice.reducer;
