import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LoginIcon from '@mui/icons-material/Login';
import FormHelperText from '@mui/material/FormHelperText';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { signupFormFields } from '../../forms/fields/formFields';
import { signupFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { cleanAuth, signup } from '../../redux/modules/auth/auth.actions';
import { authSelector } from '../../redux/modules/auth/auth.selectors';
import { locations } from '../../routes/locations';

export function Signup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fetchStatus, error, isLoggedIn } = useSelector(authSelector);
  const { handleSubmit, values, handleChange, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      [signupFormFields.name]: '',
      [signupFormFields.email]: '',
      [signupFormFields.password]: '',
      [signupFormFields.dob]: new Date(),
    },
    validationSchema: signupFormSchema,
    onSubmit: (formValues) => {
      dispatch(
        signup({
          ...formValues,
          email: formValues.email.trim(),
        }),
      );
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate((state as { from?: string })?.from || locations.dashboard(), { replace: true });
    }
  }, [isLoggedIn]);

  useEffect(
    () => () => {
      dispatch(cleanAuth());
    },
    [],
  );

  const onHaveAccount = () => {
    navigate(locations.login());
  };

  const renderTextField = (params: TextFieldProps) => (
    <TextField
      {...params}
      fullWidth
      margin="normal"
      autoComplete="bday"
      inputProps={{ ...params.inputProps, readOnly: true }}
    />
  );

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component={motion.div}
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: 4,
          paddingY: 8,
          borderRadius: 5,
        }}
        component={Paper}
      >
        <Grid item>
          <Typography component="h1" variant="h5">
            Регистрация
          </Typography>
        </Grid>

        <Grid container component="form" onSubmit={handleSubmit} flexDirection="column" autoComplete="on">
          <Grid item>
            <TextField
              id="name"
              fullWidth
              name={signupFormFields.name}
              label="Имя"
              placeholder="Иванов Иван"
              value={values[signupFormFields.name]}
              onChange={handleChange}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              autoComplete="name"
              margin="normal"
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              id="email"
              fullWidth
              name={signupFormFields.email}
              label="Email"
              value={values[signupFormFields.email]}
              onChange={handleChange}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              autoComplete="email"
              margin="normal"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name={signupFormFields.password}
              label="Пароль"
              type="password"
              value={values[signupFormFields.password]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange({
                  target: {
                    name: signupFormFields.password,
                    type: 'password',
                    value: event.target.value.replace(/\s/g, ''),
                  },
                });
              }}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth margin="normal" error={touched.dob && !!errors.dob}>
              <DatePicker
                label="Дата рождения"
                value={values[signupFormFields.dob]}
                onChange={(date) => {
                  setFieldValue(signupFormFields.dob, date);
                }}
                inputFormat="dd.MM.yyyy"
                InputProps={{
                  name: signupFormFields.dob,
                  id: signupFormFields.dob,
                  label: 'Дата рождения',
                  required: true,
                }}
                renderInput={renderTextField}
              />
              {touched.dob && <FormHelperText>{errors.dob as string}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item>
            {error && <Alert severity="error">{error.message}</Alert>}
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<LoginIcon />}
              loading={fetchStatus === fetchStatuses.pending}
              loadingPosition="end"
            >
              Регистрация
            </LoadingButton>
          </Grid>
          <Grid item>
            <Link variant="body2" onClick={onHaveAccount} sx={{ cursor: 'pointer' }}>
              Уже есть аккаунт
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
