import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { App } from './components/App';
import { ServiceWorkerWrapper } from './components/ServiceWorkerWrapper';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './console';
import './index.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  (
    <Provider store={store}>
      <CssBaseline />
      <ServiceWorkerWrapper />
      <App />
    </Provider>
  ),
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
