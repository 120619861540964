import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/hooks';
import { fetchUserProfile } from '../redux/modules/auth/auth.actions';
import { authSelector } from '../redux/modules/auth/auth.selectors';
import { locations } from '../routes/locations';

export function useAutoLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector(authSelector);

  useEffect(() => {
    if (isLoggedIn === null) {
      dispatch(fetchUserProfile());
    }
    if (isLoggedIn === false) {
      navigate(locations.login(), {
        replace: true,
        state: { from: pathname },
      });
    }
  }, [isLoggedIn]);
}
