import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { treeService } from '../../../api';
import { BoardTree, UpdateBoardTreePayload } from '../../../types/tree';

export const editBoardTree = createAction<UpdateBoardTreePayload>('boardTree/edit');

export const fetchBoardTrees = createAsyncThunk('boardTree/fetch', () => treeService.fetchBoardTrees());

export const createBoardTree = createAsyncThunk('boardTree/create', (data: BoardTree) => treeService.createBoardTree(data));

export const addLocalBoardTree = createAction<BoardTree>('boardTree/add-local');

export const deleteBoardTree = createAsyncThunk('boardTree/delete', (id: string) => treeService.deleteBoardTree(id));

export const deleteLocalBoardTree = createAction<string>('boardTree/delete-local');

export const cleanAuth = createAction('auth/clean');
