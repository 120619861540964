class Locations {
  pathname = '/';

  constructor(pathname = '/') {
    this.pathname = pathname;
  }

  home = () => this.pathname;

  calendarGrid = () => `${this.pathname}calendar-grid`;

  personalCalendar = () => `${this.pathname}personal-calendar`;

  dashboard = () => `${this.pathname}dashboard`;

  history = () => `${this.pathname}history`;

  settings = () => `${this.pathname}settings`;

  userList = () => `${this.pathname}user`;

  user = (id?: number) => `${this.pathname}user/${id !== undefined ? id : ':id'}`;

  login = () => `${this.pathname}login`;

  forgotPassword = () => `${this.pathname}forgot-password`;

  signUp = () => `${this.pathname}sign-up`;

  about = () => `${this.pathname}about`;

  notFound = () => `${this.pathname}404`;
}

export const locations = new Locations();
