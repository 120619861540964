import * as yup from 'yup';
import _get from 'lodash/get';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { round4 } from '../../../utils/number';
import { hasConsecutiveCharacters } from '../../../utils/string';

export type StrongPasswordMethodOptions = {
  minLengthMessage?: string;
  maxLengthMessage?: string;
  lowerCaseMessage?: string;
  consecutiveMessage?: string;
  sequentialMessage?: string;
  upperCaseMessage?: string;
  digitMessage?: string;
  specialMessage?: string;
};

function strongPasswordMethod(options: StrongPasswordMethodOptions = {}) {
  return this.test('strongPasswordTest', '', function check(value: string) {
    const { path, createError } = this;

    switch (Boolean(value)) {
      case value && value.length < 8:
        return createError({ path, message: options.minLengthMessage || 'Must be of minimum 8 characters length' });
      case value && value.length > 32:
        return createError({ path, message: options.minLengthMessage || 'Must be of maximum 32 characters length' });
      case !/^(?=.*[a-z])/.test(value):
        return createError({ path, message: options.lowerCaseMessage || 'Must include lowercase letter' });
      case /([\s\S])\1\1/.test(value):
        return createError({
          path,
          message: options.consecutiveMessage || 'Must not include 3 or more sequential identical characters',
        });
      case value && hasConsecutiveCharacters(value.split('')):
        return createError({
          path,
          message: options.sequentialMessage || 'Must not include 3 or more consecutive characters',
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({ path, message: options.upperCaseMessage || 'Must include uppercase letter' });
      case !/^(?=.*[0-9])/.test(value):
        return createError({ path, message: options.digitMessage || 'Must include digit' });
      // case !/^(?=.*[!@#\$%\^&\*])/.test(value):
      //   return createError({ path, message: options.specialMessage || 'Must include special character' });
      default:
        return true;
    }
  });
}

function emailDomain(domain: string, message?: string) {
  return this.test('emailDomainTest', '', function check(value: string) {
    const { path, createError } = this;

    if (value && !value.endsWith(domain)) {
      return createError({ path, message: message || `The domain must me '${domain}'` });
    }
    return true;
  });
}

function unique(message: string, path: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return this.test('uniqueTest', message, function check(list?: any[]) {
    if (!list || !list.length) {
      return true;
    }

    const mapper = (x: object) => _get(x, path);
    const set = Array.from(new Set(list.map(mapper)));
    const isUnique = list.length === set.length;

    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `${this.path}[${idx}].${path}`, message });
  });
}

function sumMin(message: string, path: string, threshold: number) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return this.test('sumMinTest', function check(list?: any[]) {
    if (!list || !list.length) {
      return true;
    }

    let idx = 0;
    const mapper = (x: object) => +_get(x, path);
    const sum = round4(
      list.reduce((res, item, index) => {
        const currentSum = res + mapper(item);

        if (currentSum < threshold && !idx) {
          idx = index;
        }

        return currentSum;
      }, 0),
    );

    if (sum < threshold) {
      return this.createError({ path: `${this.path}[${idx}].weight`, message });
    }

    return true;
  });
}

function sumMax(message: string, path: string, threshold: number) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return this.test('sumMaxTest', function check(list?: any[]) {
    if (!list || !list.length) {
      return true;
    }

    let idx = 0;
    const mapper = (x: object) => +_get(x, path);
    const sum = round4(
      list.reduce((res, item, index) => {
        const currentSum = res + mapper(item);

        if (currentSum > threshold && !idx) {
          idx = index;
        }

        return currentSum;
      }, 0),
    );

    if (sum > threshold) {
      return this.createError({ path: `${this.path}[${idx}].weight`, message });
    }

    return true;
  });
}

function phone(errorMessage) {
  return this.test('phone', errorMessage, function check(value) {
    const { path, createError } = this;

    return (value && isValidPhoneNumber(value)) || createError({ path, message: errorMessage });
  });
}

yup.addMethod<yup.StringSchema>(yup.string, 'strongPassword', strongPasswordMethod);
yup.addMethod<yup.StringSchema>(yup.string, 'emailDomain', emailDomain);
yup.addMethod<yup.StringSchema>(yup.string, 'phone', phone);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.addMethod<yup.ArraySchema<any>>(yup.array, 'unique', unique);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.addMethod<yup.ArraySchema<any>>(yup.array, 'sumMin', sumMin);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.addMethod<yup.ArraySchema<any>>(yup.array, 'sumMax', sumMax);
