import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { ColorModeContext } from '../../../helpers/theme';
import { AppBar } from '../../AppPageLayout/Navbar/NavbarElements';
import { HideOnScroll } from '../../../components/HideOnScroll';
import { LogoText } from '../../components/LogoText';
import { NavItem, NavItems } from './types';

interface INavbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  navItems: NavItems;
}

function Navbar({ open, handleDrawerOpen, handleDrawerClose, navItems }: INavbarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');
  const [playOn] = useSound(
    '/sounds/switch-off.mp3',
    { volume: 0.25, interrupt: true },
  );
  const [playOff] = useSound(
    '/sounds/switch-on.mp3',
    { volume: 0.25, interrupt: true },
  );

  const toggleColorMode = useCallback(() => {
    if (theme.palette.mode === 'dark') {
      playOff();
    } else {
      playOn();
    }
    colorMode.toggleColorMode();
  }, [theme.palette.mode]);

  const onClick = (navItem: NavItem) => {
    navigate(navItem.path);
  };

  return (
    <HideOnScroll>
      <AppBar variant="elevation">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            size="large"
            aria-label="open drawer"
            aria-haspopup="true"
            edge="start"
            sx={{ ml: 0, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <LogoText isMobile={!matchMdAndAboveView} />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <IconButton sx={{ mr: 1 }} onClick={toggleColorMode} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {navItems.map((item) => (
              <Button key={item.path} onClick={() => onClick(item)}>
                {item.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Navbar;
