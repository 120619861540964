import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import useSound from 'use-sound';
import { Copyright } from '../../components/Copyright';
import { ColorModeContext } from '../../helpers/theme';
import { locations } from '../../routes/locations';
import { parseJSON } from '../../utils/json';
import { DrawerHeader } from '../components/Sidebar/SidebarElements';
import { MenuItems } from '../components/Sidebar/types';
import Navbar from './Navbar';
import { Sidebar } from '../components';
import packageJson from '../../../package.json';
import { NavItems } from './Navbar/types';

export default function PublicPageLayout() {
  const [open, setOpen] = useState(parseJSON(localStorage.getItem('menuOpen') || 'false'));
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const isDark = theme.palette.mode === 'dark';

  const [playOn] = useSound(
    '/sounds/switch-off.mp3',
    { volume: 0.25, interrupt: true },
  );
  const [playOff] = useSound(
    '/sounds/switch-on.mp3',
    { volume: 0.25, interrupt: true },
  );

  const toggleColorMode = useCallback(() => {
    if (theme.palette.mode === 'dark') {
      playOff();
    } else {
      playOn();
    }
    colorMode.toggleColorMode();
  }, [theme.palette.mode]);

  /* eslint-disable react/no-unstable-nested-components */
  const getMenuItems = useCallback(() => [
    [
      {
        title: 'Главная',
        IconComponent: () => <DashboardIcon />,
        path: locations.home(),
      },
      {
        title: 'Календарь',
        IconComponent: () => <CalendarMonthIcon />,
        path: locations.personalCalendar(),
      },
      {
        title: 'Сетка',
        IconComponent: () => <ViewCompactIcon />,
        path: locations.calendarGrid(),
      },
      {
        title: 'О Нас',
        IconComponent: () => <InfoIcon />,
        path: locations.about(),
      },
    ],
    [
      {
        title: theme.palette.mode === 'dark' ? 'Ночь' : 'День',
        IconComponent: () => {
          if (theme.palette.mode === 'dark') {
            return <Brightness7Icon />;
          }

          return <Brightness4Icon />;
        },
        onClick: () => toggleColorMode(),
      },
      {
        title: 'Войти',
        IconComponent: () => <LoginIcon />,
        path: locations.login(),
      },
    ],
  ], [theme.palette.mode]);
  /* eslint-enable react/no-unstable-nested-components */

  const [menuItems, setMenuItems] = useState<MenuItems>(getMenuItems());

  /* eslint-disable react/no-unstable-nested-components */
  const navItems = useRef<NavItems>([
    {
      title: 'Главная',
      path: locations.home(),
    },
    {
      title: 'Календарь',
      path: locations.personalCalendar(),
    },
    {
      title: 'Сетка',
      path: locations.calendarGrid(),
    },
    {
      title: 'О Нас',
      path: locations.about(),
    },
    {
      title: 'Войти',
      path: locations.login(),
    },
  ]);
  /* eslint-enable react/no-unstable-nested-components */

  useEffect(() => {
    setMenuItems(getMenuItems());
  }, [theme.palette.mode]);

  const handleDrawerOpen = useCallback(() => {
    localStorage.setItem('menuOpen', 'true');
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    localStorage.setItem('menuOpen', 'false');
    setOpen(false);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', filter: isDark ? 'brightness(0.5)' : '' }}>
      <CssBaseline />
      <Navbar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        navItems={navItems.current}
      />
      <Sidebar onlyMobile handleDrawerClose={handleDrawerClose} open={open} menuItems={menuItems} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          m: 0,
          p: 0,
          maxWidth: '100%',
          // overflow: 'auto',
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bottom="0"
        width="100%"
        marginTop={5}
        marginBottom={16}
      >
        <Copyright sx={{ mb: 4 }} />
        <Typography variant="body2">Version: {packageJson.version}</Typography>
      </Box>
    </Box>
  );
}
