import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

export function UserList() {
  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Users &nbsp;
          <Fab color="primary" size="small" aria-label="Create User">
            <AddIcon />
          </Fab>
        </Typography>
      </Box>
      Table
    </Container>
  );
}
