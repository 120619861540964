export * from './tree';
export * from './auth';

export enum formFields {
  email = 'email',
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  gender = 'gender',
  age = 'age',
  date = 'date',
  time = 'time',
  haveChildren = 'haveChildren',
  name = 'name',
  type = 'type',
  resource = 'resource',
  token = 'token',
}

export enum userFormFields {
  email = 'email',
  fullName = 'fullName',
  mobile = 'mobile',
  status = 'status',
  group = 'group',
}
