import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../redux/hooks';
import { fetchArticles } from '../../redux/modules/article/article.actions';
import { articleSelector } from '../../redux/modules/article/article.selectors';
import { container, item } from '../../utils/animation';
import { HistoryItem } from './HistoryItem';

export const History = () => {
  const dispatch = useAppDispatch();

  const { articles } = useSelector(articleSelector);

  useEffect(() => {
    dispatch(fetchArticles());
  }, []);

  return (
    <Container
      sx={{ height: '100%' }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ my: 5 }}>
        <Grid
          item
          container
          flexDirection="row"
          xs={12}
          sm={8}
          component={motion.div}
          initial={{ y: '-150%' }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Grid item>
            <Typography variant="h4">История</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          spacing={2}
          component={motion.div}
          variants={container}
          initial="hidden"
          animate="show"
        >
          {articles.map((article) => (
            <Grid
              item
              component={motion.div}
              variants={item}
              key={article.id}
              xs={12}
              md={4}
            >
              <HistoryItem data={article} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
