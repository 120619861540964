// import type {
//   FetchDaysInfoResponse,
// } from '../types/config';
import { ApiService } from './common/ApiService';
import DAYS_INFO from './mock/DAYS_INFO';

class ConfigService extends ApiService {
  pathname = 'config';

  // TODO: this.get<FetchDaysInfoResponse>(`${this.pathname}/days-info`);
  // eslint-disable-next-line class-methods-use-this
  fetchDaysInfo = async () => Promise.resolve({ data: DAYS_INFO });
}

export const configService = new ConfigService();
