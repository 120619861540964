import { PaginatedRequest } from '../types/api';
import {
  FetchUsersResponse,
  FetchUserResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  CreateUserRequest,
  CreateUserResponse,
} from '../types/user';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserService extends ApiService {
  pathname = 'user';

  fetchUsers = async (data: PaginatedRequest) =>
    this.get<FetchUsersResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUser = async (id: number) => this.get<FetchUserResponse>(`${this.pathname}/${id}`);

  createUser = async (user: CreateUserRequest) =>
    this.post<CreateUserResponse, Omit<CreateUserRequest, 'id'>>(`${this.pathname}`, user);

  updateUser = async ({ id, ...user }: UpdateUserRequest) =>
    this.put<UpdateUserResponse, Omit<UpdateUserRequest, 'id'>>(`${this.pathname}/${id}`, user);
}

export const userService = new UserService();
