import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Image } from 'mui-image';

interface CaptureImageDialogPros {
  imageUrl?: string;
  fileName: string;
  open: boolean;
  handleClose: () => void;
}

export const CaptureImageDialog = (props: CaptureImageDialogPros) => {
  const { open, fileName, imageUrl, handleClose } = props;

  const onDownload = () => {
    if (imageUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = imageUrl;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle textAlign="center">
        Сохранить изображение
      </DialogTitle>
      <DialogContent>
        <Image src={imageUrl!} fit="contain" alt="capture" duration={1500} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={handleClose}>Закрыть</Button>
        <Button variant="contained" color="primary" onClick={onDownload} autoFocus>
          Скачать
        </Button>
      </DialogActions>
    </Dialog>
  );
};
