import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { articleReducer } from './modules/article/article.reducer';
import { authReducer } from './modules/auth/auth.reducer';
import { captureImageReducer } from './modules/captureImage/captureImage.reducer';
import { configReducer } from './modules/config/config.reducer';
import { treeReducer } from './modules/tree/tree.reducer';
import { userReducer } from './modules/user/user.reducer';

export const store = configureStore({
  reducer: {
    article: articleReducer,
    config: configReducer,
    auth: authReducer,
    user: userReducer,
    tree: treeReducer,
    captureImage: captureImageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
