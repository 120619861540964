import { OutputData } from '@editorjs/editorjs';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Grid from '@mui/material/Grid';
import AddCommentIcon from '@mui/icons-material/AddComment';
import IconButton from '@mui/material/IconButton';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useCaptureImage } from '../../hooks/useCaptureImage';
import { useAppDispatch } from '../../redux/hooks';
import { createArticle } from '../../redux/modules/article/article.actions';
import { authSelector } from '../../redux/modules/auth/auth.selectors';
import { fetchDaysInfo } from '../../redux/modules/config/config.actions';
import {
  deleteBoardTree,
  deleteLocalBoardTree,
  editBoardTree,
} from '../../redux/modules/tree/tree.actions';
import { BoardTree, TreeType, UpdateBoardTreePayload } from '../../types/tree';
import { ArticleDialog, ArticleDialogData } from '../ArticleDialog';
import { AsyncDialog } from '../AsyncDialog';
import { AsyncDialogRef } from '../AsyncDialog/AsyncDialog';
import { ConfirmationDialog, ConfirmationDialogData } from '../ConfirmationDialog';
import { ProjectionTree } from './ProjectionTree';
import { SimpleTree } from './SimpleTree';

import './treeController.css';

interface TreeControllerProps {
  tree: BoardTree;
}

export const TreeController = ({ tree }: TreeControllerProps) => {
  const dispatch = useAppDispatch();
  const { capture } = useCaptureImage();
  const theme = useTheme();
  const { isLoggedIn } = useSelector(authSelector);
  const asyncConfirmationDialogRef = useRef<AsyncDialogRef<ConfirmationDialogData, boolean>>(null);
  const asyncArticleDialogRef = useRef<AsyncDialogRef<ArticleDialogData, OutputData | undefined>>(null);
  const isDark = theme.palette.mode === 'dark';
  const isProjection = tree.type === TreeType.Projection;

  const updateTreeData = (data: UpdateBoardTreePayload) => {
    dispatch(editBoardTree(data));
  };

  const deleteTree = async () => {
    if (await asyncConfirmationDialogRef?.current?.show({ text: 'Вы уверены что хотите удалить дерево?' })) {
      if (!isLoggedIn) {
        dispatch(deleteLocalBoardTree(tree.id));
      } else {
        await dispatch(deleteBoardTree(tree.id));
      }
    }
  };

  const commentTree = async () => {
    try {
      const text = await asyncArticleDialogRef?.current?.show({ date: tree.date });

      console.log('[commentTree text]', text);

      if (!text) {
        return;
      }

      dispatch(createArticle({
        id: `article-${Date.now()}`,
        date: tree.date,
        data: text,
      }));
    } catch (error) {
      console.error('[commentTree error]', error);
    }
  };

  const captureBoardTrees = () => {
    const fileName = tree.type === TreeType.Projection
      ? `tree-${moment(tree.dateNull).format('YYYY-MM-DD')}-${moment(tree.date).format('YYYY-MM-DD')}`
      : `tree-${moment(tree.date).format('YYYY-MM-DD')}`;

    capture(`tree-controller-${tree.id}`, fileName);
  };

  useEffect(() => {
    dispatch(fetchDaysInfo());
  }, []);

  // eslint-disable-next-line prefer-template
  const className = 'tree__controller' +
    (isDark ? ' tree__controller--dark' : '') +
    (isProjection ? ' tree__controller--projection' : '');

  return (
    <>
      <Grid
        item
        container
        id={`tree-controller-${tree.id}`}
        className={className}
      >
        <IconButton
          color="success"
          aria-label="capture image"
          className="tree__controller__camera-btn"
          data-html2canvas-ignore="true"
          component="label"
          onClick={captureBoardTrees}
        >
          <PhotoCamera />
        </IconButton>
        {
          (tree.canDelete) && (
            <IconButton
              color="error"
              aria-label="delete tree"
              className="tree__controller__delete-btn"
              data-html2canvas-ignore="true"
              component="label"
              onClick={deleteTree}
            >
              <DeleteIcon />
            </IconButton>
          )
        }

        {
          (isLoggedIn) && (
            <IconButton
              color="error"
              aria-label="comment tree"
              className="tree__controller__comment-btn"
              data-html2canvas-ignore="true"
              component="label"
              onClick={commentTree}
            >
              <AddCommentIcon />
            </IconButton>
          )
        }

        {
          tree.type === TreeType.Simple
            ? <SimpleTree tree={tree} updateTreeData={updateTreeData} />
            : <ProjectionTree tree={tree} updateTreeData={updateTreeData} />
        }
      </Grid>

      <AsyncDialog
        ref={asyncConfirmationDialogRef}
        dialog={ConfirmationDialog}
      />

      <AsyncDialog
        ref={asyncArticleDialogRef}
        dialog={ArticleDialog}
      />
    </>
  );
};
