import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import Box from '@mui/system/Box';
import MaterialReactTable, {
  MRT_ColumnDef as MRTColumnDef,
  MRT_FullScreenToggleButton as MRTFullScreenToggleButton,
} from 'material-react-table';
import { MRT_Localization_RU as MRTLocalizationRU } from 'material-react-table/locales/ru';

import React, { useMemo } from 'react';
import { TooltipToggleButton } from '../ToggleButton';

export interface ListDataSetItem {
  date: string;
  physical: number;
  emotional: number;
  intellectual: number;
}

interface BiorhythmsListProps {
  data: ListDataSetItem[];
}

export const BiorhythmsList: React.FC<BiorhythmsListProps> = (props) => {
  const { data } = props;
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);

  const handleFilters = (event: React.MouseEvent<HTMLElement>, newFilters: string[]) => {
    setSelectedFilters(newFilters);
  };

  const columns = useMemo<MRTColumnDef<ListDataSetItem>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Дата',
        size: 80,
        enableColumnActions: false,
      },
      {
        accessorKey: 'physical',
        header: 'Физ.',
        size: 80,
        enableColumnActions: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'emotional',
        header: 'Эмоц.',
        size: 95,
        enableColumnActions: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'intellectual',
        header: 'Интел.',
        size: 100,
        enableColumnActions: false,
        filterFn: 'equals',
      },
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableBottomToolbar={false}
      enableColumnResizing={false}
      // enableColumnVirtualization
      enableGlobalFilterModes={false}
      enablePagination={false}
      enablePinning={false}
      enableRowNumbers={false}
      enableRowVirtualization
      muiTableContainerProps={{ sx: { maxHeight: '244px' } }}
      rowVirtualizerProps={{ overscan: 5 }}
      // columnVirtualizerProps={{ overscan: 2 }}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <ToggleButtonGroup
            value={selectedFilters}
            onChange={handleFilters}
            aria-label="filter"
          >
            <TooltipToggleButton
              value="physical"
              aria-label="physical"
              sx={{ padding: '6px' }}
              TooltipProps={{ enterTouchDelay: 0, title: '0% Физический' }}
            >
              Физ.
            </TooltipToggleButton>
            <TooltipToggleButton
              value="emotional"
              aria-label="emotional"
              sx={{ padding: '6px' }}
              TooltipProps={{ enterTouchDelay: 0, title: '0% Эмоционаяльный' }}
            >
              Эмоц.
            </TooltipToggleButton>
            <TooltipToggleButton
              value="intellectual"
              aria-label="intellectual"
              sx={{ padding: '6px' }}
              TooltipProps={{ enterTouchDelay: 0, title: '0% Интеллектуальный' }}
            >
              Интел.
            </TooltipToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      renderToolbarInternalActions={({ table }) => (
        <Box>
          <MRTFullScreenToggleButton table={table} />
        </Box>
      )}
      localization={MRTLocalizationRU}
      state={{
        columnFilters: selectedFilters.map((key) => ({ id: key, value: 0 })),
      }}
    />
  );
};
