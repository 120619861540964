import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useSelector } from 'react-redux';
import useSound from 'use-sound';
import { HideOnScroll } from '../../../components/HideOnScroll';
import { ColorModeContext } from '../../../helpers/theme';
import { LogoText } from '../../components/LogoText';
import { AppBar } from './NavbarElements';
import { authSelector } from '../../../redux/modules/auth/auth.selectors';
import { Sidebar } from '../../components';
import { MenuItems } from '../../components/Sidebar/types';

interface IMobileNavbarProps {
  handleLogout: () => void;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  menuItems: MenuItems;
  open: boolean;
}

export const MobileNavbar = (props: IMobileNavbarProps) => {
  const { handleLogout, menuItems, open, handleDrawerOpen, handleDrawerClose } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useSelector(authSelector);
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const isDark = theme.palette.mode === 'dark';
  const [playOn] = useSound(
    '/sounds/switch-off.mp3',
    { volume: 0.25, interrupt: true },
  );
  const [playOff] = useSound(
    '/sounds/switch-on.mp3',
    { volume: 0.25, interrupt: true },
  );

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  const toggleColorMode = useCallback(() => {
    if (theme.palette.mode === 'dark') {
      playOff();
    } else {
      playOn();
    }
    colorMode.toggleColorMode();
  }, [theme.palette.mode]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    // navigate(locations.myProfile());
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>Профиль</MenuItem>
      <MenuItem onClick={handleLogout}>Выйти</MenuItem>
    </Menu>
  );

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" elevation={0} sx={{ filter: isDark ? 'brightness(0.5)' : '' }}>
          <Toolbar disableGutters sx={{ px: { xs: 0, md: 28 }, display: 'flex', justifyContent: 'space-between' }}>
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              >
                <MenuIcon sx={{ fontSize: '24px' }} />
              </IconButton>
              <LogoText isMobile />
            </Grid>
            <Grid item>
              <IconButton sx={{ mr: 1 }} onClick={toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user?.name
                    .split(' ')
                    .slice(0, 2)
                    .map((i: string) => i.charAt(0))
                    .join('')}
                </Avatar>
              </IconButton>
            </Grid>
            <Sidebar
              menuItems={menuItems}
              open={open}
              handleDrawerClose={handleDrawerClose}
            />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMenu}
    </>
  );
};
