import React from 'react';
import { IBoardSimpleTree, UpdateBoardTreePayload } from '../../types/tree';
import { Tree } from '../Tree';

interface SimpleTreeProps {
  tree: IBoardSimpleTree;
  readOnly?: boolean;
  updateTreeData: (data: UpdateBoardTreePayload) => void;
}

export const SimpleTree = (props: SimpleTreeProps) => {
  const { tree, updateTreeData } = props;

  return (
    <Tree
      date={tree.date}
      title={tree.title}
      readOnly={props.readOnly}
      setDate={(newDate) => updateTreeData({ id: tree.id, date: newDate })}
      setTitle={(newTitle) => updateTreeData({ id: tree.id, title: newTitle })}
    />
  );
};
