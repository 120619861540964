import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserProfileResponse } from '../../../types/auth';
import {
  BoardTree,
  CreateBoardTreeResponse,
  DeleteBoardTreeResponse,
  FetchBoardTreesResponse,
  TreeType,
  UpdateBoardTreePayload,
} from '../../../types/tree';
import { parseJSON } from '../../../utils/json';
import { fetchUserProfile } from '../auth/auth.actions';
import {
  addLocalBoardTree,
  cleanAuth,
  createBoardTree,
  deleteBoardTree,
  deleteLocalBoardTree,
  editBoardTree,
  fetchBoardTrees,
} from './tree.actions';
import { TreeState } from './tree.types';

const loadInitialState = parseJSON(localStorage.getItem('initialStateTree') || '') as TreeState;

if (loadInitialState?.boardTrees?.length) {
  loadInitialState.boardTrees = loadInitialState.boardTrees.map((bt) => {
    if (bt.id === 'today' || bt.type === TreeType.Projection) {
      bt.date = moment().format('YYYY-MM-DD');
    }

    return bt;
  });
}

const saveInitialStateTree = (state: TreeState) => localStorage.setItem('initialStateTree', JSON.stringify(state));

const initialState: TreeState = loadInitialState || {
  fetchStatus: fetchStatuses.idle,
  addStatus: fetchStatuses.idle,
  boardTrees: [{
    id: 'today',
    type: TreeType.Simple,
    canDelete: false,
    title: 'Сегодня',
    date: moment().format('YYYY-MM-DD'),
  }, {
    id: 'me',
    type: TreeType.Projection,
    canDelete: false,
    titleNull: 'Моя дата',
    title: 'Моя проекция',
    dateNull: moment().format('YYYY-MM-DD'),
    date: moment().format('YYYY-MM-DD'),
  }],
  error: null,
};

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBoardTrees.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchBoardTrees.fulfilled, (state, action: PayloadAction<FetchBoardTreesResponse>) => {
      for (const item of action.payload.data) {
        if (state.boardTrees.findIndex((bt) => bt.id === item.id) < 0) {
          if (item.type === TreeType.Projection) {
            item.date = moment().format('YYYY-MM-DD');
          }

          state.boardTrees.push(item);
        }
      }
      state.fetchStatus = fetchStatuses.success;

      saveInitialStateTree(state);
    });
    builder.addCase(fetchBoardTrees.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createBoardTree.pending, (state, action) => {
      state.boardTrees.push(action.meta.arg);
      state.addStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(createBoardTree.fulfilled, (state, action: PayloadAction<CreateBoardTreeResponse>) => {
      state.boardTrees = state.boardTrees.map((bt) => {
        if (bt.id === (action as any)?.meta?.arg?.id) {
          return action.payload.data;
        }

        return bt;
      });
      state.addStatus = fetchStatuses.success;

      saveInitialStateTree(state);
    });
    builder.addCase(createBoardTree.rejected, (state, action) => {
      state.addStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteBoardTree.pending, (state, action) => {
      state.boardTrees = state.boardTrees.filter((bt) => bt.id !== action.meta.arg);
      state.addStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(deleteBoardTree.fulfilled, (state, action: PayloadAction<DeleteBoardTreeResponse>) => {
      state.boardTrees = state.boardTrees.filter((bt) => bt.id !== action.payload.data.id);
      state.addStatus = fetchStatuses.success;

      saveInitialStateTree(state);
    });
    builder.addCase(deleteBoardTree.rejected, (state, action) => {
      state.addStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(editBoardTree.type, (state, action: PayloadAction<UpdateBoardTreePayload>) => {
      state.boardTrees = state.boardTrees.map((bt) => {
        if (bt.id === action.payload.id) {
          return { ...bt, ...action.payload };
        }

        return bt;
      });

      saveInitialStateTree(state);
    });
    builder.addCase(addLocalBoardTree.type, (state, action: PayloadAction<BoardTree>) => {
      state.boardTrees.push(action.payload);

      saveInitialStateTree(state);
    });
    builder.addCase(deleteLocalBoardTree.type, (state, action: PayloadAction<string>) => {
      state.boardTrees = state.boardTrees.filter((bt) => bt.id !== action.payload);

      saveInitialStateTree(state);
    });

    /**
     * Update "me" tree with date of birthday
     */

    builder.addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<FetchUserProfileResponse>) => {
      state.boardTrees = state.boardTrees.map((bt) => {
        if (bt.id === 'me') {
          return {
            ...bt,
            dateNull: moment(action.payload.data.dob).format('YYYY-MM-DD'),
          };
        }

        return bt;
      });
    });

    builder.addCase(cleanAuth.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const treeReducer = treeSlice.reducer;
