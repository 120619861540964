import ReplayIcon from '@mui/icons-material/Replay';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { locations } from '../../routes/locations';

export const About = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(locations.home());
  };

  const openDocument = () => {
    window.open('https://docs.google.com/document/d/1X98xCDIyQcbQS0ORnEhJHiHMPmnM0v0tKyOfth0Cn1k/edit?usp=sharing', '_blank', 'noreferrer');
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container flexDirection="column" alignItems="center" justifyContent="center" height="100%">
        <Grid item sx={{ mt: 5, overflow: 'hidden' }}>
          <Typography
            component={motion.h3}
            variant="h3"
            color="text.primary"
            textAlign="center"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Добро пожаловать.
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 5, overflow: 'hidden' }}>
          <Typography
            component={motion.h6}
            variant="body2"
            color="text.secondary"
            textAlign="center"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            Сайт явялется интелектульной собственностью.
            <br />
            Разработчик - <b>Сорокин Иван Петрович</b>.
            <br />
            Автор Тринометрического Календаря - <b>Пономарев Юрий Александрович</b>.
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 10, overflow: 'hidden' }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DocumentScannerIcon />}
            onClick={openDocument}
            component={motion.button}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            Документация
          </Button>
        </Grid>
        <Grid item sx={{ mt: 10, overflow: 'hidden' }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ReplayIcon />}
            onClick={goHome}
            component={motion.button}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            На главную
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
