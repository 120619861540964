import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PreviewIcon from '@mui/icons-material/Preview';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import SimpleImage from '@editorjs/simple-image';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import Embed from '@editorjs/embed';
import InlineCode from '@editorjs/inline-code';
import Strikethrough from '@sotaproject/strikethrough';
import Undo from 'editorjs-undo';
import DragDrop from 'editorjs-drag-drop';
import ColorPlugin from 'editorjs-text-color-plugin';
import Hyperlink from 'editorjs-hyperlink';
import Alert from 'editorjs-alert';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import {
  ItalicInlineTool,
  UnderlineInlineTool,
} from 'editorjs-inline-tool';

import './index.css';

export interface ArticleEditorRef {
  save: () => Promise<OutputData> | undefined;
}

interface ArticleEditorProps {
  date?: string;
  data?: OutputData;
  readOnly?: boolean;
}

export const ArticleEditor = React.forwardRef<ArticleEditorRef, ArticleEditorProps>((props, ref) => {
  const blocks: OutputData = props.data || {
    time: Date.now(),
    blocks: [],
    version: EditorJS.version,
  };
  const theme = useTheme();
  const editorCore = useRef<EditorJS | null>(null);
  const [readOnly, setReadOnly] = useState(props.readOnly || false);

  const isDark = theme.palette.mode === 'dark';

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleReady = () => {
    if (editorCore?.current) {
      // eslint-disable-next-line no-underscore-dangle
      const editor = (editorCore.current as any)._editorJS;

      (() => new Undo({ editor }))();
      (() => new DragDrop(editor))();
    }
  };

  const handlePreview = () => {
    if (editorCore?.current) {
      // eslint-disable-next-line no-underscore-dangle
      const editor = (editorCore.current as any)._editorJS;

      editor?.readOnly.toggle();
    }
  };

  useImperativeHandle(ref, () => ({
    save: () => editorCore?.current?.save(),
  }));

  useEffect(() => () => setReadOnly(false), []);

  const ReactEditorJS = createReactEditorJS();

  return (
    <div className={`article-editor ${isDark ? 'article-editor--dark' : ''}`}>
      <Tooltip enterTouchDelay={0} title="Предпросмотр">
        <IconButton
          id={props.date}
          color="primary"
          aria-label="preview"
          data-html2canvas-ignore="true"
          component="label"
          sx={{ position: 'absolute', top: 60, right: 0, display: readOnly ? 'none' : 'flex' }}
          onClick={handlePreview}
        >
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      <ReactEditorJS
        defaultValue={blocks}
        onInitialize={handleInitialize}
        onReady={handleReady}
        readOnly={readOnly}
        placeholder="Давай напишем удивительную историю!"
        tools={{
          header: {
            class: Header,
            config: {
              placeholder: 'Введите заголовок',
            },
            tunes: ['anyTuneName'],
            inlineToolbar: ['link'],
            shortcut: 'CMD+SHIFT+H',
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ['anyTuneName'],
          },
          anyTuneName: {
            class: AlignmentTuneTool,
            config: {
              default: 'left',
              blocks: {
                header: 'center',
                list: 'right',
              },
            },
          },
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
          alert: {
            class: Alert,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+A',
            config: {
              defaultType: 'primary',
              messagePlaceholder: 'Введите что нибудь',
            },
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Введите цитату',
              captionPlaceholder: 'Автор цитаты',
            },
          },
          embed: {
            class: Embed,
            inlineToolbar: true,
          },
          image: {
            class: SimpleImage,
            inlineToolbar: ['link'],
          },
          delimiter: {
            class: Delimiter,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+R',
          },
          // italic: ItalicInlineTool,
          underline: UnderlineInlineTool,
          strikethrough: Strikethrough,
          Color: {
            class: ColorPlugin,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+L',
            config: {
              defaultStyle: 'ordered',
            },
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          table: {
            class: Table,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+T',
          },
          hyperlink: {
            class: Hyperlink,
            inlineToolbar: true,
          },
        }}
        i18n={{
          /* eslint-disable quote-props */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              'blockTunes': {
                'toggler': {
                  'Click to tune': 'Нажмите, чтобы настроить',
                  'or drag to move': 'или перетащите',
                },
              },
              'inlineToolbar': {
                'converter': {
                  'Convert to': 'Конвертировать в',
                },
              },
              'toolbar': {
                'toolbox': {
                  'Add': 'Добавить',
                },
              },
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              'Text': 'Параграф',
              'Heading': 'Заголовок',
              'List': 'Список',
              'Warning': 'Примечание',
              'Checklist': 'Чеклист',
              'Quote': 'Цитата',
              'Image': 'Изображение',
              'Code': 'Код',
              'Delimiter': 'Разделитель',
              'Raw HTML': 'HTML-фрагмент',
              'Table': 'Таблица',
              'Link': 'Ссылка',
              Hyperlink: 'Ссылка',
              'Marker': 'Маркер',
              'Bold': 'Полужирный',
              'Italic': 'Курсив',
              'InlineCode': 'Моноширинный',
            },

            /**
             * Section for passing translations to the external tools classes
             */
            tools: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
               * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
               */
              'warning': { // <-- 'Warning' tool will accept this dictionary section
                'Title': 'Название',
                'Message': 'Сообщение',
              },

              /**
               * Link is the internal Inline Tool
               */
              'link': {
                'Add a link': 'Вставьте ссылку',
              },
              /**
               * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
               */
              'stub': {
                'The block can not be displayed correctly.': 'Блок не может быть отображен',
              },
              'list': {
                'Ordered': 'Нумерованный',
                'Unordered': 'Маркированный',
              },
              table: {
                'Add row above': 'Добавить строку выше',
                'Add row below': 'Добавить строку ниже',
                'Delete row': 'Удалить строку',
                'Add column to left': 'Добавить столбец слева',
                'Add column to right': 'Добавить столбец справа',
                'Delete column': 'Удалить столбец',
                'With headings': 'С заголовками',
                'Without headings': 'Без заголовков',
                'Heading': 'Заголовок',
              },
              image: {
                'Add Border': 'Граница',
                'Stretch Image': 'Растянуть',
                'Add Background': 'Добавить Фон',
              },
              header: {
                'Heading 1': 'Заголовок 1',
                'Heading 2': 'Заголовок 2',
                'Heading 3': 'Заголовок 3',
                'Heading 4': 'Заголовок 4',
                'Heading 5': 'Заголовок 5',
                'Heading 6': 'Заголовок 6',
              },
              hyperlink: {
                Save: 'Сохранить',
                'Select target': 'Выберите цель',
                'Select rel': 'Выберите связь',
              },
              alert: {
                'Primary': 'Основной',
                'Secondary': 'Второстепенный',
                'Info': 'Информация',
                'Success': 'Успешно',
                'Warning': 'Внимание',
                'Danger': 'Опасно',
                'Light': 'Светлый',
                'Dark': 'Темный',
                'Left': 'Слева',
                'Center': 'Центр',
                'Right': 'Справа',
              },
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              'delete': {
                'Delete': 'Удалить',
              },
              'moveUp': {
                'Move up': 'Переместить вверх',
              },
              'moveDown': {
                'Move down': 'Переместить вниз',
              },
            },
          },
          /* eslint-enable quote-props */
        }}
      />
    </div>
  );
});
