import {
  CreateArticleRequest,
  CreateArticleResponse,
  DeleteArticleResponse,
  FetchArticlesResponse,
  IArticle,
} from '../types/article';
import { ApiService } from './common/ApiService';

class ArticleService extends ApiService {
  pathname = 'article';

  fetchArticles = async () => this.get<FetchArticlesResponse>(`${this.pathname}`);

  createArticle = async (data: IArticle) => this.post<CreateArticleResponse, CreateArticleRequest>(`${this.pathname}`, data);

  deleteArticle = async (id: string) => this.delete<DeleteArticleResponse>(`${this.pathname}/${id}`);
}

export const articleService = new ArticleService();
