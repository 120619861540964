import type { BoardTree, DeleteBoardTreeResponse, FetchBoardTreesResponse } from '../types/tree';
import { CreateBoardTreeRequest, CreateBoardTreeResponse } from '../types/tree';
import { ApiService } from './common/ApiService';

class TreeService extends ApiService {
  pathname = 'tree';

  fetchBoardTrees = async () => this.get<FetchBoardTreesResponse>(`${this.pathname}`);

  createBoardTree = async (data: BoardTree) => this.post<CreateBoardTreeResponse, CreateBoardTreeRequest>(`${this.pathname}`, data);

  deleteBoardTree = async (id: string) => this.delete<DeleteBoardTreeResponse>(`${this.pathname}/${id}`);
}

export const treeService = new TreeService();
