import type {
  LoginResponse,
  LoginRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  RestorePasswordRequest,
  RestorePasswordResponse,
  FetchUserProfileResponse,
  SignupResponse,
  SignupRequest,
} from '../types/auth';
import { ApiService } from './common/ApiService';

class AuthService extends ApiService {
  pathname = 'auth';

  login = async (data: LoginRequest) => this.post<LoginResponse, LoginRequest>(`${this.pathname}/local/login`, data);

  signup = async (data: SignupRequest) => this.post<SignupResponse, SignupRequest>(`${this.pathname}/local/signup`, data);

  logout = async () => this.post(`${this.pathname}/logout`, {});

  fetchUserProfile = async () => this.get<FetchUserProfileResponse>(`${this.pathname}/profile`);

  forgotPassword = async (data: ForgotPasswordRequest) =>
    this.post<ForgotPasswordResponse, ForgotPasswordRequest>(`${this.pathname}/forgot-password`, data);

  restorePassword = async (data: RestorePasswordRequest) =>
    this.post<RestorePasswordResponse, RestorePasswordRequest>(`${this.pathname}/restore-password`, data);
}

export const authService = new AuthService();
