import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetCaptureImagePayload } from '../../../types/captureImage';
import { setCaptureImage, setCaptureImageOpen } from './captureImage.actions';
import { CaptureImageState } from './captureImage.types';

const initialState: CaptureImageState = {
  fileName: '',
  imageUrl: '',
  open: false,
};

const captureImageSlice = createSlice({
  name: 'captureImage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCaptureImage.type, (state, action: PayloadAction<SetCaptureImagePayload>) => {
      state.fileName = action.payload.fileName;
      state.imageUrl = action.payload.imageUrl;

      if (action.payload.open !== undefined) {
        state.open = action.payload.open;
      } else {
        state.open = true;
      }
    });
    builder.addCase(setCaptureImageOpen.type, (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    });
  },
});

export const captureImageReducer = captureImageSlice.reducer;
