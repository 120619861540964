import * as yup from 'yup';
import '../common/extends';
import {
  formFields,
  userFormFields,
} from '../../fields/formFields';
import { emailSchema } from '../common/emailSchema';
import { passwordSchema } from '../common/passwordSchema';
import { phoneSchema } from '../common/phoneSchema';

export * from './tree';
export * from './auth';

export const formSchema = yup.object({
  [formFields.email]: emailSchema,
  [formFields.password]: passwordSchema,
});

const baseUserFormSchema = {
  [userFormFields.mobile]: phoneSchema,
  [userFormFields.fullName]: yup
    .string()
    .max(150, 'Name must be at most 150 characters')
    .required('Full Name is required')
    .trim(),
  [userFormFields.status]: yup.number().positive().required('Status is required'),
  [userFormFields.group]: yup.number().positive().required('Group is required'),
};

export const editUserFormSchema = yup.object(baseUserFormSchema);

export const createUserFormSchema = yup.object({
  ...baseUserFormSchema,
  [userFormFields.email]: emailSchema,
});
