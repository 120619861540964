import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import { AppRoutes } from '../../routes/AppRoutes';
import { GlobalCaptureImageController } from '../CaptureImageDialog';
import { CaptureImageProvider } from '../../hooks/useCaptureImage';
import { ScrollToTop } from '../ScrollToTop';
import './reset.scss';

export function AppInnerRouter() {
  const { pathname } = useLocation();

  return (
    <>
      <ScrollToTop />
      <CaptureImageProvider>
        <SnackbarProvider maxSnack={10}>
          <GlobalCaptureImageController />
          <AnimatePresence mode="sync" initial={false}>
            <AppRoutes />
          </AnimatePresence>
        </SnackbarProvider>
      </CaptureImageProvider>
    </>
  );
}
