import createCache from '@emotion/cache';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import React from 'react';
import COLORS from '../constants/colors';

const ColorModeContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});

const lightPalette = {
  mode: 'light',
};

const lightComponents = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: COLORS.WHITE,
        color: COLORS.MAIN_DARK,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: COLORS.WHITE,
        color: COLORS.MAIN_DARK,
      },
    },
  },
};

const darkPalette = {
  mode: 'dark',
};

const darkComponents = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: COLORS.MAIN_DARK,
        color: COLORS.WHITE,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: COLORS.MAIN_DARK,
        color: COLORS.WHITE,
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        filter: 'brightness(0.5)',
      },
    },
  },
};

const typography = {
  typography: {
    fontFamily: '\'Inter\', sans-serif',
  },
};

const lightTheme = createTheme({
  palette: lightPalette,
  components: lightComponents,
  ...typography,
  spacing: 4,
} as ThemeOptions);

const darkTheme = createTheme({
  palette: darkPalette,
  components: darkComponents,
  ...typography,
  spacing: 4,
} as ThemeOptions);

const cacheLtr = createCache({
  key: 'muiltr',
});

export { lightTheme, darkTheme, cacheLtr, ColorModeContext };
