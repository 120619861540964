import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserProfileResponse } from '../../../types/auth';
import { cleanAuth, fetchUserProfile, forgotPassword, login, logout, restorePassword, signup } from './auth.actions';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  user: null,
  isLoggedIn: null,
  fetchStatus: fetchStatuses.idle,
  forgotPasswordStatus: fetchStatuses.idle,
  restorePasswordStatus: fetchStatuses.idle,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(logout.rejected, (state, action) => {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(login.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state) => {
      // localStorage.setItem('accessToken', action.payload?.data?.accessToken);
      // localStorage.setItem('refreshToken', action.payload?.data?.refreshToken);

      state.isLoggedIn = true;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(signup.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(signup.fulfilled, (state) => {
      // localStorage.setItem('accessToken', action.payload?.data?.accessToken);
      // localStorage.setItem('refreshToken', action.payload?.data?.refreshToken);

      state.isLoggedIn = true;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPasswordStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPasswordStatus = fetchStatuses.success;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPasswordStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(restorePassword.pending, (state) => {
      state.restorePasswordStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(restorePassword.fulfilled, (state) => {
      state.restorePasswordStatus = fetchStatuses.success;
    });
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.restorePasswordStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserProfile.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<FetchUserProfileResponse>) => {
      state.user = action.payload.data;
      state.isLoggedIn = true;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.isLoggedIn = false;
      state.error = action.error;
    });

    builder.addCase(cleanAuth.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const authReducer = authSlice.reducer;
