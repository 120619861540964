import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { articleService } from '../../../api';
import { IArticle } from '../../../types/article';

export const fetchArticles = createAsyncThunk('article/fetch', () => articleService.fetchArticles());

export const createArticle = createAsyncThunk('article/create', (data: IArticle) => articleService.createArticle(data));

export const deleteArticle = createAsyncThunk('article/delete', (id: string) => articleService.deleteArticle(id));

export const cleanArticle = createAction('article/clean');
