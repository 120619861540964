import { AnimatePresence, MotionConfig } from 'framer-motion';
import { useEffect, useState } from 'react';
import i18nInitial from '../../utils/i18n';
import { SplashScreen } from '../SplashScreen';
import { AppView } from './AppView';
import './reset.scss';

export function App() {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    i18nInitial((v) => setTranslationsLoaded(v));
  }, []);

  return (
    <MotionConfig reducedMotion="user">
      <AnimatePresence>
        {!translationsLoaded ? <SplashScreen /> : null}
      </AnimatePresence>
      <AppView />
    </MotionConfig>
  );
}
