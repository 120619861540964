import React from 'react';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import Tooltip, { TooltipProps as ITooltipProps } from '@mui/material/Tooltip';

type TooltipToggleButtonProps = ToggleButtonProps & {
  TooltipProps: Omit<ITooltipProps, 'children'>;
};

export const TooltipToggleButton = React.forwardRef<any, TooltipToggleButtonProps>(
  ({ TooltipProps, ...props }, ref) => (
    <Tooltip {...TooltipProps}>
      <ToggleButton ref={ref} {...props} />
    </Tooltip>
  ));
