import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { treeSelector } from '../../redux/modules/tree/tree.selectors';
import { TreeType } from '../../types/tree';

interface TreeQuickLinksProps {
  paddingLeft?: number;
}

export const TreeQuickLinks = (props: TreeQuickLinksProps) => {
  const { paddingLeft = 0 } = props;
  const theme = useTheme();
  const [value, setValue] = useState<string>('today');
  const [isMounted, setIsMounted] = useState(false);
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');
  const { boardTrees } = useSelector(treeSelector);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    event.stopPropagation();

    setValue(newValue);
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    setTimeout(() => {
      const section = document.querySelector(`#board-tree-${value}`);

      if (!section) {
        return;
      }

      section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // section.scroll({ top: section.scrollHeight, behavior: 'smooth' });
    }, 100);
  }, [value]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Paper
      sx={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: (theme.components?.MuiAppBar?.styleOverrides?.colorPrimary as any)?.backgroundColor,
        p: 0,
        m: 0,
        width: matchMdAndAboveView ? `calc(100% - ${paddingLeft}px)` : '100%',
        right: 0,
        bottom: 0,
        zIndex: 2,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable prevent tabs example"
      >
        {boardTrees.map((bt) => (
          <Tab value={bt.id} key={bt.id} label={bt.type === TreeType.Simple ? bt.title : bt.titleNull} />
        ))}
      </Tabs>
    </Paper>
  );
};
