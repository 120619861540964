import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { motion } from 'framer-motion';
import moment, { Moment } from 'moment/moment';
import React, { useEffect, useState } from 'react';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import Calendar from 'rc-year-calendar';
import { useSelector } from 'react-redux';
import { DaysInfoModal } from '../../components/DaysInfoModal';
import { getHeadBeast } from '../../helpers/calendar';
import { useCaptureImage } from '../../hooks/useCaptureImage';
import { useAppDispatch } from '../../redux/hooks';
import { fetchDaysInfo } from '../../redux/modules/config/config.actions';
import { configSelector } from '../../redux/modules/config/config.selectors';
import { authSelector } from '../../redux/modules/auth/auth.selectors';

import 'rc-year-calendar/locales/rc-year-calendar.ru';
import './personal-calendar.css';

type CalendarDataSource = {
  id: number,
  name: string,
  color: string,
  beastNumber: number,
  beastId: number,
  startDate: Date,
  endDate: Date
}

const currentYear = new Date().getFullYear();

const pickerDate = new Date();

pickerDate.setHours(0, 0, 0, 0);
pickerDate.setFullYear(currentYear, 0, 1);

export const PersonalCalendar = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { capture } = useCaptureImage();
  const [openDaysModal, setOpenDaysModal] = useState(false);
  const localDob = +(localStorage.getItem('personalCalendarDob') || 0);
  const [userDob, setUserDob] = useState<Moment>(moment(localDob || '01-01-1984'));
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [year, setYear] = useState(currentYear);
  const [dataSource, setDateSource] = useState<CalendarDataSource[]>([
    {
      id: 0,
      name: 'Крыса',
      color: 'blue',
      startDate: new Date(year, 1, 1),
      endDate: new Date(year, 1, 1),
      beastId: 1,
      beastNumber: 1,
    },
  ]);

  const { colors, beasts, daysInfo, numberBeasts, ...config } = useSelector(configSelector);
  const { user } = useSelector(authSelector);

  useEffect(() => {
    dispatch(fetchDaysInfo());
  }, []);

  useEffect(() => {
    const startDateOfTheYear = moment([year]).startOf('year');
    const sysDateNull = moment(moment(config.dateNull), 'YYYY-MM-DD');
    let dateCurrent = moment([year]).startOf('year');

    if (userDob) {
      const dateNull = moment(userDob).format('YYYY-MM-DD');
      const dateNullProjection = moment(dateNull, 'YYYY-MM-DD');
      const diffSysDateNullFromDateNull = Math.abs(sysDateNull.diff(dateNullProjection, 'days'));

      dateCurrent = dateCurrent.subtract(diffSysDateNullFromDateNull, 'days');
    }

    const headBeast = getHeadBeast(numberBeasts, dateCurrent, config.dateNull);

    const newDataSource: CalendarDataSource[] = [{
      id: 0,
      startDate: moment(startDateOfTheYear).toDate(),
      endDate: moment(startDateOfTheYear).toDate(),
      color: colors[headBeast.colorId],
      name: beasts[headBeast.beastId],
      beastId: headBeast.beastId,
      beastNumber: headBeast.beastNumber,
    }];

    let iteration = 0;

    do {
      const numDays = (iteration + headBeast.beastNumber) % 60;
      const beastId = numDays % 12;
      const colorId = numDays % 10;
      const beastNumber = numberBeasts[beastId][Math.floor(colorId / 2)];

      startDateOfTheYear.add(1, 'days');
      dateCurrent.add(1, 'days');

      newDataSource.push({
        id: +startDateOfTheYear,
        startDate: moment(startDateOfTheYear).toDate(),
        endDate: moment(startDateOfTheYear).toDate(),
        color: colors[colorId],
        name: beasts[beastId],
        beastNumber,
        beastId,
      });

      iteration++;
    } while (startDateOfTheYear.year() <= year);

    setDateSource(newDataSource);
  }, [colors, beasts, year, userDob]);

  useEffect(() => {
    if (user?.dob) {
      setUserDob(moment(user.dob));
    }
  }, [user?.dob]);

  const captureGridPicture = () => {
    capture('calendar', `personal-calendar-${moment(userDob).format('YYYY-MM-DD')}-${year}`);
  };

  const getBeastTooltip = (beastNumber: number) => daysInfo[beastNumber === 0 ? 59 : beastNumber - 1]?.shortDescription || '';

  // eslint-disable-next-line arrow-body-style
  const customDayRenderer = (element: HTMLElement, date: Date) => {
    // console.log('customDayRenderer', date, element);

    return element;
  };

  const customDataSourceRenderer = (element: HTMLElement, date: Date, ds: CalendarDataSource[]) => {
    // console.log('customDataSourceRenderer', date, element, ds);

    const isDark = theme.palette.mode === 'dark';

    if (ds?.[0]) {
      const sub = document.createElement('sub');

      sub.style.color = theme.palette.text.primary;

      const beastContent = document.createTextNode(`${ds[0].beastId + 1}`);

      sub.appendChild(beastContent);

      element.style.color = ds[0].color;
      element.title = getBeastTooltip(ds[0].beastNumber);

      if (!isDark) {
        element.style.backgroundColor = 'lightgray';
      }

      element.appendChild(sub);
    }

    return element;
  };

  const renderTextField = (params: TextFieldProps) => (
    <TextField
      {...params}
      helperText={null}
      inputProps={{ ...params.inputProps, readOnly: true }}
    />
  );

  return (
    <Container
      sx={{ height: '100%' }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 5 }}
        component={motion.div}
        initial={{ y: '-150%' }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Grid item container flexDirection="row" xs={12} sm={8}>
          <Grid item>
            <Typography variant="h4">Личный Календарь</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            mt: { xs: 5, sm: 0 },
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
          }}
        >
          <DatePicker
            label="Дата Рождения"
            value={moment(userDob, 'YYYY-MM-DD').toDate()}
            inputFormat="dd.MM.yyyy"
            onChange={(newValue) => {
              if (newValue) {
                const newDob = moment(newValue);

                localStorage.setItem('personalCalendarDob', `${+newDob}`);

                setUserDob(newDob);
              }
            }}
            renderInput={renderTextField}
          />
        </Grid>
      </Grid>

      <Grid
        item
        id="calendar"
        component={motion.div}
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Calendar
          key={`personal-calendar-theme-${theme.palette.mode}-${daysInfo.length}`}
          language="ru"
          // style={'background' as any}
          style={'custom' as any}
          allowOverlap={false}
          dataSource={dataSource}
          weekStart={1}
          year={year}
          onYearChanged={(event) => setYear(event.currentYear)}
          customDayRenderer={customDayRenderer}
          customDataSourceRenderer={customDataSourceRenderer}
        />
      </Grid>

      <DaysInfoModal
        open={openDaysModal}
        date={selectedDate}
        handleClose={() => setOpenDaysModal(false)}
      />
    </Container>
  );
};
