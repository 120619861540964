import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { motion } from 'framer-motion';
import moment, { Moment } from 'moment/moment';
import React, { useEffect, useState } from 'react';
import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { CalendarGridYear } from '../../components/CalendarGridYear';
import { CalendarGridYearDayInfo } from '../../components/CalendarGridYear/CalendarGridYear';
import { DaysInfoModal } from '../../components/DaysInfoModal';
import { useCaptureImage } from '../../hooks/useCaptureImage';
import { useAppDispatch } from '../../redux/hooks';
import { fetchDaysInfo } from '../../redux/modules/config/config.actions';

export const CalendarGrid = () => {
  const dispatch = useAppDispatch();
  const { capture } = useCaptureImage();
  const [openDaysModal, setOpenDaysModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [year, setYear] = useState(new Date().getFullYear());

  const pickerDate = new Date();

  pickerDate.setHours(0, 0, 0, 0);
  pickerDate.setFullYear(year, 0, 1);

  useEffect(() => {
    dispatch(fetchDaysInfo());
  }, []);

  const onDayClick = (data: CalendarGridYearDayInfo) => {
    setSelectedDate(data.date);
    setOpenDaysModal(true);
  };

  const captureGridPicture = () => {
    capture('calendar-grid-year', `calendar-grid-${year}`);
  };

  const renderTextField = (params: TextFieldProps) => (
    <TextField
      {...params}
      helperText={null}
      inputProps={{ ...params.inputProps, readOnly: true }}
    />
  );

  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 5 }}
        component={motion.div}
        initial={{ y: '-150%' }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Grid item container flexDirection="row" xs={10}>
          <Grid item>
            <Typography variant="h4">Календарная Сетка</Typography>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <IconButton color="primary" aria-label="take picture" component="label" onClick={captureGridPicture}>
              <PhotoCamera />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            views={['year']}
            label="Year only"
            value={pickerDate}
            inputFormat="yyyy"
            onChange={(newValue) => {
              if (newValue) {
                setYear(moment(newValue).get('years'));
              }
            }}
            renderInput={renderTextField}
          />
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%', overflow: 'auto' }}>
        <CalendarGridYear year={year} onDayClick={onDayClick} />
      </Grid>

      <DaysInfoModal
        open={openDaysModal}
        date={selectedDate}
        handleClose={() => setOpenDaysModal(false)}
      />
    </Container>
  );
};
