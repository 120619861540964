import { OutputData } from '@editorjs/editorjs';
import Paper from '@mui/material/Paper';
import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import { ArticleDialog, ArticleDialogData } from '../../components/ArticleDialog';
import { ArticleEditor } from '../../components/ArticleEditor';
import { AsyncDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { SimpleTree } from '../../components/TreeController/SimpleTree';
import { IArticle } from '../../types/article';
import { TreeType } from '../../types/tree';

interface HistoryItemProps {
  data: IArticle;
}

export const HistoryItem = (props: HistoryItemProps) => {
  const asyncArticleDialogRef = useRef<AsyncDialogRef<ArticleDialogData, OutputData | undefined>>(null);

  const renderTree = () => (
    <Box
      sx={{
        transform: 'translate(0px,-60px) scale(0.6, 0.4);',
        maxHeight: 130,
      }}
    >
      <SimpleTree
        tree={{
          type: TreeType.Simple,
          id: props.data.id,
          date: props.data.date,
          title: props.data.date,
          canDelete: false,
        }}
        readOnly
        updateTreeData={() => {
          // empty handler
        }}
      />
    </Box>
  );

  const openArticle = async () => {
    try {
      const text = await asyncArticleDialogRef?.current?.show({
        id: props.data.id,
        date: props.data.date,
        data: props.data.data,
      });

      if (!text) {
        return;
      }
    } catch (error) {
      console.error('[openArticle error]', error);
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }} component={Paper} elevation={1}>
      <CardActionArea onClick={openArticle}>
        {renderTree()}
        <CardContent sx={{ overflow: 'hidden' }}>
          <Typography variant="h5" component="div">
            {props.data.date}
          </Typography>
          <Box sx={{ transform: 'translate(0px, -40px) scale(0.4, 0.3);', maxHeight: 100 }}>
            <ArticleEditor readOnly date={props.data.date} data={props.data.data} />
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Поделиться
        </Button>
      </CardActions>

      <AsyncDialog
        ref={asyncArticleDialogRef}
        dialog={ArticleDialog}
      />
    </Card>
  );
};
