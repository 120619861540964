import moment, { Moment } from 'moment/moment';
import { BeastInfo } from '../utils/calendar';

export const getHeadBeast = (numberBeasts: number[][], date: Moment, sysDateNull: string): BeastInfo => {
  const dateNull = moment(moment(sysDateNull), 'YYYY-MM-DD');
  const dateCurrent = moment(date, 'YYYY-MM-DD');

  const days = Math.abs(dateNull.diff(dateCurrent, 'days')) % 60;

  const headBeastId = days % 12;
  const headColorId = days % 10;
  const headNumber = numberBeasts[headBeastId][Math.floor(headColorId / 2)];

  return {
    colorId: headColorId,
    beastId: headBeastId,
    beastNumber: headNumber,
  };
};

export const calculateHeadBodyFoot = (props: { date: string, dateNull?: string, configDateNull: string, numberBeasts: number[][] }) => {
  const sysDateNull = moment(moment(props.configDateNull), 'YYYY-MM-DD');
  const dateNull = sysDateNull;
  const dateNullProjection = moment(moment(props.dateNull), 'YYYY-MM-DD');
  let dateCurrent = moment(props.date, 'YYYY-MM-DD');

  if (props.dateNull) {
    const diffSysDateNullFromDateNull = Math.abs(sysDateNull.diff(dateNullProjection, 'days'));

    dateCurrent = dateCurrent.subtract(diffSysDateNullFromDateNull, 'days');
  }

  let days = Math.abs(dateNull.diff(dateCurrent, 'days')) % 60;
  let months = Math.abs(dateNull.diff(dateCurrent, 'months')) % 60;
  let years = Math.abs(dateNull.diff(dateCurrent, 'years')) % 60;

  if (dateNull > dateCurrent) {
    days = (60 - days) % 60;
    months = (60 - months - 1) % 60;
    years = (60 - years - 1) % 60;
  }

  const headBeastId = days % 12;
  const headColorId = days % 10;
  const headNumber = props.numberBeasts[headBeastId][Math.floor(headColorId / 2)];

  const feetBeastId = years % 12;
  const feetColorId = years % 10;
  const feetNumber = props.numberBeasts[feetBeastId][Math.floor(feetColorId / 2)];

  let mm = +dateCurrent.format('MM') - 1;

  if (mm % 2 === 1) mm -= 1;

  // console.log(days, months, years, feetColorId, mm);

  const bodyBeastId = months % 12;
  const bodyColorId = (feetColorId + mm) % 10;
  const bodyNumber = props.numberBeasts[bodyBeastId][Math.floor(bodyColorId / 2)];

  return {
    date: props.date,
    head: {
      beastId: headBeastId,
      colorId: headColorId,
      number: headNumber,
    },
    body: {
      beastId: bodyBeastId,
      colorId: bodyColorId,
      number: bodyNumber,
    },
    feet: {
      beastId: feetBeastId,
      colorId: feetColorId,
      number: feetNumber,
    },
  };
};

export const calculateDay = (props: { date: string, dateNull?: string, configDateNull: string, numberBeasts: number[][] }) => {
  const sysDateNull = moment(moment(props.configDateNull), 'YYYY-MM-DD');
  const dateNull = sysDateNull;
  const dateNullProjection = moment(moment(props.dateNull), 'YYYY-MM-DD');
  let dateCurrent = moment(props.date, 'YYYY-MM-DD');

  if (props.dateNull) {
    const diffSysDateNullFromDateNull = Math.abs(sysDateNull.diff(dateNullProjection, 'days'));

    dateCurrent = dateCurrent.subtract(diffSysDateNullFromDateNull, 'days');
  }

  let days = Math.abs(dateNull.diff(dateCurrent, 'days')) % 60;
  let months = Math.abs(dateNull.diff(dateCurrent, 'months')) % 60;

  let years = Math.abs(dateNull.diff(dateCurrent, 'years')) % 60;

  if (dateNull > dateCurrent) {
    days = (60 - days) % 60;
    months = (60 - months - 1) % 60;
    years = (60 - years - 1) % 60;
  }

  const headBeastId = days % 12;
  const headColorId = days % 10;
  const headNumber = props.numberBeasts[headBeastId][Math.floor(headColorId / 2)];

  const feetBeastId = years % 12;
  const feetColorId = years % 10;
  const feetNumber = props.numberBeasts[feetBeastId][Math.floor(feetColorId / 2)];

  let mm = +dateCurrent.format('MM') - 1;

  if (mm % 2 === 1) mm -= 1;

  // console.log(days, months, years, feetColorId, mm);

  const bodyBeastId = months % 12;
  const bodyColorId = (feetColorId + mm) % 10;
  const bodyNumber = props.numberBeasts[bodyBeastId][Math.floor(bodyColorId / 2)];

  const leftTopGuardBeastId = (bodyBeastId + 4) % 12;
  const leftTopGuardColorId = (bodyColorId + 4) % 10;
  const leftTopGuardNumber = props.numberBeasts[leftTopGuardBeastId][Math.floor(leftTopGuardColorId / 2)];

  const leftBotGuardBeastId = (bodyBeastId + 2) % 12;
  const leftBotGuardColorId = (bodyColorId + 8) % 10;
  const leftBotGuardNumber = props.numberBeasts[leftBotGuardBeastId][Math.floor(leftBotGuardColorId / 2)];

  const rightTopGuardBeastId = (bodyBeastId + 8) % 12;
  const rightTopGuardColorId = (bodyColorId + 6) % 10;
  const rightTopGuardNumber = props.numberBeasts[rightTopGuardBeastId][Math.floor(rightTopGuardColorId / 2)];

  const rightBotGuardBeastId = (bodyBeastId + 10) % 12;
  const rightBotGuardColorId = (bodyColorId + 2) % 10;
  const rightBotGuardNumber = props.numberBeasts[rightBotGuardBeastId][Math.floor(rightBotGuardColorId / 2)];

  const topBeltNumber = (bodyNumber + feetNumber) % 60;
  const topBeltBeastId = ((topBeltNumber || 60) - 1) % 12;
  const topBeltColorId = ((topBeltNumber || 60) - 1) % 10;

  const botBeltNumber = (bodyNumber + headNumber) % 60;
  const botBeltBeastId = ((botBeltNumber || 60) - 1) % 12;
  const botBeltColorId = ((botBeltNumber || 60) - 1) % 10;

  const reliquaryNumber = (headNumber + bodyNumber + feetNumber) % 60;
  const reliquaryBeastId = ((reliquaryNumber || 60) - 1) % 12;
  const reliquaryColorId = ((reliquaryNumber || 60) - 1) % 10;

  return {
    date: props.date,
    head: {
      beastId: headBeastId,
      colorId: headColorId,
      number: headNumber,
    },
    body: {
      beastId: bodyBeastId,
      colorId: bodyColorId,
      number: bodyNumber,
    },
    feet: {
      beastId: feetBeastId,
      colorId: feetColorId,
      number: feetNumber,
    },
    reliquary: {
      beastId: reliquaryBeastId,
      colorId: reliquaryColorId,
      number: reliquaryNumber,
    },
    topBelt: {
      beastId: topBeltBeastId,
      colorId: topBeltColorId,
      number: topBeltNumber,
    },
    botBelt: {
      beastId: botBeltBeastId,
      colorId: botBeltColorId,
      number: botBeltNumber,
    },
    leftTopGuard: {
      beastId: leftTopGuardBeastId,
      colorId: leftTopGuardColorId,
      number: leftTopGuardNumber,
    },
    leftBotGuard: {
      beastId: leftBotGuardBeastId,
      colorId: leftBotGuardColorId,
      number: leftBotGuardNumber,
    },
    rightTopGuard: {
      beastId: rightTopGuardBeastId,
      colorId: rightTopGuardColorId,
      number: rightTopGuardNumber,
    },
    rightBotGuard: {
      beastId: rightBotGuardBeastId,
      colorId: rightBotGuardColorId,
      number: rightBotGuardNumber,
    },
  };
};

export const getSameDates = (date: Moment): Moment[] => {
  const dateCurrent = moment(date, 'YYYY-MM-DD');

  const dates: Moment[] = [];

  const dateStep = moment(dateCurrent);

  for (let i = dateStep; i.subtract(60, 'days').year() === dateCurrent.year();) {
    //
  }

  for (let i = dateStep; i.add(60, 'days').year() === dateCurrent.year();) {
    dates.push(moment(dateStep));
  }

  return dates;
};
