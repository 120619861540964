import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateUserRequest,
  UpdateUserRequest,
} from '../../../types/user';

export const fetchUsers = createAsyncThunk('user/fetch-all', (data: PaginatedRequest) => userService.fetchUsers(data));

export const fetchUser = createAsyncThunk('user/get', (id: number) => userService.fetchUser(id));

export const createUser = createAsyncThunk('user/create', (user: CreateUserRequest) => userService.createUser(user));

export const updateUser = createAsyncThunk('user/update', (user: UpdateUserRequest) => userService.updateUser(user));

export const cleanUsers = createAction('user/clean');

export const resetEditUser = createAction('user/reset-edit');
