import TextField, { TextFieldProps } from '@mui/material/TextField';
import Box from '@mui/system/Box';
import styled from '@mui/system/styled';
import { useEffect, useRef, useState } from 'react';

type InlineEditProps = {
  value: string;
  setValue: (value: string) => void;
};

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiInputBase-root': {
    height: '30px',
    color: 'white',
    opacity: 1,
    fontSize: '20px',
    fontWeight: '700',

    '& input': { textAlign: 'center', padding: 0 },

    '& .Mui-disabled': {
      color: 'white',
      // WebkitTextFillColor: 'white',
    },
  },
}));

export const InlineEdit = ({ value, setValue }: InlineEditProps) => {
  const [editingValue, setEditingValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
    setEditMode(false);
  };

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
    }
  }, [editMode]);

  if (!editMode) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '30px',
          color: 'white',
          opacity: 1,
          fontSize: '20px',
          fontWeight: '700',
        }}
        onClick={() => setEditMode(true)}
      >
        <h3>{value}</h3>
      </Box>
    );
  }

  return (
    <StyledTextField
      inputRef={inputRef}
      variant="standard"
      margin="none"
      type="text"
      fullWidth
      autoFocus
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={!editMode}
      InputProps={{
        disableUnderline: !editMode,
        // endAdornment: (
        //   <InputAdornment position="end" data-html2canvas-ignore="true">
        //     <IconButton onClick={() => setEditMode(!editMode)}>
        //       {editMode ? <SaveIcon /> : <EditIcon />}
        //     </IconButton>
        //   </InputAdornment>
        // ),
      }}
    />
  );
};
