import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useImperativeHandle } from 'react';
import useSound from 'use-sound';
import { HookOptions, PlayOptions } from 'use-sound/dist/types';

export interface SoundPlayButtonRef {
  play: (options?: PlayOptions) => void;
  stop: () => void;
  pause: () => void;
  isPlaying: () => boolean;
}

interface SoundPlayButtonProps {
  src: string,
  options?: HookOptions;
}

export const SoundPlayButton = React.forwardRef<SoundPlayButtonRef, SoundPlayButtonProps>((props, ref) => {
  const { src, options } = props;
  const [isPlaying, setIsPlaying] = React.useState(false);

  const [playSound, { pause, stop }] = useSound(
    src,
    {
      volume: 0.25,
      interrupt: true,
      ...options,
      onplay: () => {
        setIsPlaying(true);
        if (options.onplay) {
          options.onplay();
        }
      },
      onend: () => {
        setIsPlaying(false);
        if (options.onend) {
          options.onend();
        }
      },
    },
  );

  useEffect(() => {
    stop();
  }, [src]);

  useImperativeHandle(ref, () => ({
    play: (playOptions?: PlayOptions) => {
      playSound(playOptions);
    },
    stop: () => {
      setIsPlaying(false);
      stop();
    },
    isPlaying: () => isPlaying,
    pause: () => {
      setIsPlaying(false);
      pause();
    },
  }));

  const onPlayButton = () => {
    if (isPlaying) {
      setIsPlaying(false);
      return pause();
    }

    setIsPlaying(true);
    return playSound();
  };

  return (
    <Tooltip enterTouchDelay={0} title="Enter - проиграть/пауза">
      <IconButton
        id="pay_days_info_button"
        color="primary"
        aria-label="play sound"
        data-html2canvas-ignore="true"
        component="label"
        onClick={onPlayButton}
      >
        {isPlaying ? (<PauseCircleIcon />) : (<PlayCircleIcon />)}
      </IconButton>
    </Tooltip>
  );
});
