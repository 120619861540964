import Paper from '@mui/material/Paper';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LoginIcon from '@mui/icons-material/Login';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { loginFormFields } from '../../forms/fields/formFields';
import { loginFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { cleanAuth, login } from '../../redux/modules/auth/auth.actions';
import { authSelector } from '../../redux/modules/auth/auth.selectors';
import { locations } from '../../routes/locations';

export function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fetchStatus, error, isLoggedIn } = useSelector(authSelector);
  const { handleSubmit, values, handleChange, touched, errors } = useFormik({
    initialValues: {
      [loginFormFields.email]: '',
      [loginFormFields.password]: '',
    },
    validationSchema: loginFormSchema,
    initialTouched: { [loginFormFields.email]: true, [loginFormFields.password]: true },
    onSubmit: (formValues) => {
      dispatch(
        login({
          ...formValues,
          email: formValues.email.trim(),
        }),
      );
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate((state as { from?: string })?.from || locations.dashboard(), { replace: true });
    }
  }, [isLoggedIn]);

  useEffect(
    () => () => {
      dispatch(cleanAuth());
    },
    [],
  );

  const onForgotPassword = () => {
    navigate(locations.forgotPassword());
  };

  const onSignup = () => {
    navigate(locations.signUp());
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component={motion.div}
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: 4,
          paddingY: 8,
          borderRadius: 5,
        }}
        component={Paper}
      >
        <Grid item>
          <Typography component="h1" variant="h5">
            Войти
          </Typography>
        </Grid>

        <Grid item>
          <form onSubmit={handleSubmit} autoComplete="on">
            <TextField
              id="email"
              fullWidth
              name={loginFormFields.email}
              label="Email"
              value={values[loginFormFields.email]}
              onChange={handleChange}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              autoComplete="email"
              margin="normal"
              required
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name={loginFormFields.password}
              label="Пароль"
              type="password"
              value={values[loginFormFields.password]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange({
                  target: {
                    name: loginFormFields.password,
                    type: 'password',
                    value: event.target.value.replace(/\s/g, ''),
                  },
                });
              }}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              autoComplete="current-password"
            />
            {error && <Alert severity="error">{error.message}</Alert>}
            <LoadingButton
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<LoginIcon />}
              loading={fetchStatus === fetchStatuses.pending}
              loadingPosition="end"
            >
              Войти
            </LoadingButton>
            <Grid container flexDirection="column">
              <Grid item>
                <Link variant="body2" onClick={onSignup} sx={{ cursor: 'pointer' }}>
                  Зарегистрироваться
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" onClick={onForgotPassword} sx={{ cursor: 'pointer' }}>
                  Забыли пароль?
                </Link>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
