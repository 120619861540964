import moment from 'moment';
import React, { useRef } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useSelector } from 'react-redux';
import { AsyncDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { useCaptureImage } from '../../hooks/useCaptureImage';
import { useAppDispatch } from '../../redux/hooks';
import { authSelector } from '../../redux/modules/auth/auth.selectors';
import { createBoardTree, addLocalBoardTree } from '../../redux/modules/tree/tree.actions';
import { BoardTree } from '../../types/tree';
import { CreateTreeDialog } from './CreateTreeDialog';

const actions = [
  { icon: <AddIcon />, name: 'Create', label: 'Добавить' },
  { icon: <PhotoCameraIcon />, name: 'Image', label: 'Изображение' },
];

interface HomeSpeedDialProps {
  bottom?: number;
}

export default function HomeSpeedDial({ bottom = 16 }: HomeSpeedDialProps) {
  const dispatch = useAppDispatch();
  const { capture } = useCaptureImage();
  const { isLoggedIn } = useSelector(authSelector);
  const asyncCreateTreeDialogRef = useRef<AsyncDialogRef<void, BoardTree>>(null);

  const addTree = async (data: BoardTree) => {
    if (!isLoggedIn) {
      dispatch(addLocalBoardTree(data));
    } else {
      await dispatch(createBoardTree(data));
    }

    setTimeout(() => {
      const section = document.querySelector(`#board-tree-${data.id}`);

      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const captureBoardTrees = () => {
    capture('board-threes', `board-trees-${moment().format('YYYY-MM-DD')}`);
  };

  const onActionClick = async (action: string) => {
    if (action === 'Create') {
      const tree = await asyncCreateTreeDialogRef?.current?.show();

      if (tree) {
        addTree(tree);
      }
    }
    if (action === 'Image') {
      captureBoardTrees();
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.label}
            tooltipOpen
            onClick={() => onActionClick(action.name)}
          />
        ))}
      </SpeedDial>

      <AsyncDialog ref={asyncCreateTreeDialogRef} dialog={CreateTreeDialog} />
    </>
  );
}
