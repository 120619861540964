import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { Image } from 'mui-image';
import Grid from '@mui/material/Grid';
import COLORS from '../../constants/colors';

export const SplashScreen: React.FC = () => {
  const ref = useRef(null);

  return (
    <Grid
      container
      ref={ref}
      component={motion.div}
      initial={{ opacity: 1, transform: 'scale(1)' }}
      exit={{ opacity: 0, transform: 'scale(10)' }}
      transition={{ duration: 1 }}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.MAIN_DARK,
        zIndex: 9999,
      }}
    >
      <Grid item sx={{ width: '100px', height: '100px' }}>
        <Image duration={500} style={{ filter: 'invert(100%)' }} src="/logo.png" fit="contain" alt="logo" width="100%" height="100%" />
      </Grid>
    </Grid>
  );
};
