import React from 'react';
import Container from '@mui/system/Container';

export function User() {
  return (
    <Container component="main" maxWidth="xl">
      User details
    </Container>
  );
}
