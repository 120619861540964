import { OutputData } from '@editorjs/editorjs';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { parseJSON } from '../../utils/json';
import { ArticleEditor, ArticleEditorRef } from '../ArticleEditor';
import { IAsyncDialogProps } from '../AsyncDialog/AsyncDialog';

export interface ArticleDialogData {
  id?: string;
  data?: OutputData;
  date?: string;
}

export function ArticleDialog(props: IAsyncDialogProps<OutputData | undefined, void, ArticleDialogData>) {
  const [fullScreen, setFullScreen] = useState(parseJSON(localStorage.getItem('articleDialogFullScreen') || 'false'));
  const articleEditorRef = useRef<ArticleEditorRef>(null);

  useEffect(() => {
    localStorage.setItem('articleDialogFullScreen', fullScreen);
  }, [fullScreen]);

  const handleSave = async () => {
    const data = await articleEditorRef.current?.save();

    props.onOk(data);
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container flexDirection="row" alignItems="center" justifyContent="center">
          <Grid item>
            Описание на {props.data?.date}
          </Grid>
          <IconButton
            color="primary"
            aria-label="full screen"
            data-html2canvas-ignore="true"
            component="label"
            sx={{ position: 'absolute', top: 30, right: 0 }}
            onClick={() => setFullScreen((fs) => !fs)}
          >
            {fullScreen ? (<FullscreenExitIcon />) : (<FullscreenIcon />)}
          </IconButton>
          <IconButton
            color="primary"
            aria-label="close"
            data-html2canvas-ignore="true"
            component="label"
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => props.onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ArticleEditor ref={articleEditorRef} date={props.data?.date} data={props.data?.data} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={() => props.onClose()}>Закрыть</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
}
