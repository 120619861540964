export default [
    {
        id: 1,
        beastNumber: 1,
        shortDescription: '(Голубая Крыса) – Выбор цели.',
        title: 'Пробуждение к свету',
        description: 'Это идея или мечта, маяк и цель к которой будет устремлено всё последующее действие. Это посев семени и пробуждение к свету. Первое мгновение жизни и просветление. Хрупкий росток пробивший твёрдую скорлупу из подземного заточения. Величайшее мастерство познания природной мудрости в том, чтобы в этот день уловить посев одного из множества мельчайших семечек и затем изо дня в день не потерять из фокуса своего внимания каждый этап удивительных превращений и роста до полной кроны с плодами и новым семенем для следующего круга.',
    },
    {
        id: 2,
        beastNumber: 2,
        shortDescription: '(Голубой Волк) – Планирование маршрута.',
        title: 'Небесный луч',
        description: 'Антивирус от проникновения в человека виртуальных программ из внешнего пространства называется “сглазами” и “порчами”. Крылатый Воин прокладывает виртуальный маршрут к намеченному результату всего пути с выявлением возможных препятствий или осложнений, по ходу следования на период 60 дней. В этот день мы получаем подсказки, что необходимо будет нам в дальнейшем на сложных изломах и виражах маршрута. Внимательность к таким подсказкам поможет нам вовремя уклониться от опасностей на пути следования и избежать возможные неприятности.',
    },
    {
        id: 3,
        beastNumber: 3,
        shortDescription: '(Красный Тигр) – Активизация потребления и питания.',
        title: 'Активизация питания и потребления',
        description: 'Этот знак можно смело назвать “Обжорой”, но при этом и “Гурманом”, потому что он как никто другой способен во всей полноте оценить все вкусовые возможности продуктов питания. Он расширяет пространство наших питательных возможностей через привязку их к нашему эмоциональному восприятию. В этот день мы усиленно питаемся тем, что усиливает нашу чувствительность и эмоциональность. Энергии пространства соприкасаются с нашей сферой энергетической защиты и оцениваются через тепловую сетку вкусового анализатора а возможность н пропуска в наше жизненное пространство для восприятия и переработки.',
    },
    {
        id: 4,
        beastNumber: 4,
        shortDescription: '(Красный Кот) – Активное кроветворение и мышление.',
        title: 'Активация кроветворения и мышления',
        description: 'В этот день происходит усиление кровообращения головного мозга и объём воспринимаемого и обрабатываемого информационного потока. Благодаря этому знаку определяется объем крови необходимой для здорового питания и прогрева всего организма на новый цикл в 60 дней. Это контроль эмоциональности разумом с использованием активного перенаправления мыслительного потока в нужное русло с изменением интенсивности чувственного всплеска. В этот день происходит анализ всех возможностей которыми наш организм может воспользоваться для питания и поддержания нормальной жизнедеятельности.',
    },
    {
        id: 5,
        beastNumber: 5,
        shortDescription: '(Золотой Дракон) – Гармонизация эмоциональности.',
        title: 'Гармонизация эмоциональности',
        description: 'Это день гармонизации работы сердца и восстановления баланса между всеми четырьмя его составляющими. Энергия знака Души приводится в состояние уравновешенного включения всех коленно - локтевых стражей Души, что позволяет не только обнаружить отклонения в работе отдельных стражей, но и увидеть в действии процесс их восстановления с подсказками ситуационного проявления в окружающем пространстве. Во всём организме происходит выравнивание внутреннего огня через баланс ключевых точек на теле - четырёх фильтров, контролирующих входящие и выходящие из тела энергетические потоки - “Стражи Души”.',
    },
    {
        id: 6,
        beastNumber: 6,
        shortDescription: '(Золотая Змея) – Проявление срединного пути.',
        title: 'Проявление срединного Пути',
        description: 'В этот день энергетические сетки земной поверхности восстанавливают свою природную соразмерность и перераспределяют через свои каналы потоки, подпитывающие энергиями всю живую материю, находящуюся на нашей планете. Аналогичный процесс происходит и в нашем организме восстанавливая баланс во всех клетках нашего тела, получая дополнительную подпитку от самого большого аккумулятора - Земли. Отражение таких процессов происходит как на всём пищеварительном тракте, так и на коррекции нашего позвоночного столба. Это подстройка к энергетическим потокам текущим в сетках земли для резонансного восстановления гармонии энергий в нашем теле.',
    },
    {
        id: 7,
        beastNumber: 7,
        shortDescription: '(Белая Лошадь) – Иллюзорное проявление подсознательного.',
        title: 'Иллюзорное проявление подсознательного',
        description: 'День структурирования в воде сеток приёма информации из внешних источников. Включается процесс очищения информационных ячеек воды во всех формах её пребывания, включая клеточный уровень нашего организма. Для этого очищения блокируется приём и проникновение информационных потоков на уровне энергий. Рекомендуется в этот день не пропускать к обработке полученную информацию и не удерживать её в своеобразном карантине до конца этого дня. Иллюзия призрачного образа должна быть проверена временем и принята лишь та её часть, что не раствориться подобно миражу.',
    },
    {
        id: 8,
        beastNumber: 8,
        shortDescription: '(Белая Овца) – Структура логического мышления.',
        title: 'Структура логического мышления',
        description: 'Этот знак является очищающим фильтром кристаллических структур, сохраняющих архивированные материалы нашей долгосрочной памяти в костных тканях скелетного каркаса организма. Структуры архивирующие энергию памяти проходят тестирование на стандартный эталон сжатия отфильтрованных данных с параметрами плотности до проявленной материи в нашем организме. В этот день работа почек находится в особом режиме своеобразного выпаривания жидкости до материально проявленного осадка в костных тканях. Отдельные информационные блоки пересматриваются и восстанавливаются их связи для полной завершенности к последующему структурному сжатию.',
    },
    {
        id: 9,
        beastNumber: 9,
        shortDescription: '(Чёрная обезьяна) – Изоляция.',
        title: 'Изоляция и отшельничество',
        description: 'В этот день “Хозяйки внутреннего Мира и Времени” остывает и уплотняется всё пространство вокруг нас создавая благоприятные условия для ограждения наших чувств от внезапного воспламенения. Находясь в замкнутом мире “отчуждения”, мы способны реально оценить наш индивидуальный жизненный потенциал и получить мудрые подсказки, как его не растрачивать понапрасну и как в дальнейшем его поправить. Аскетизм и затворничество в этот день очень уместны для того, чтобы отступить в тень и отстранённо понаблюдать за теми явлениями, которые ранят и истощают наше сердце незаметно забирая из ран запас наших жизненных сил. Мудрость в отрешённости и рациональности.',
    },
    {
        id: 10,
        beastNumber: 10,
        shortDescription: '(Чёрный Петух) – Сумеречный фронт.',
        title: 'Сумеречное пространство',
        description: 'В этот день разумнее всего сложить “крылья” закры шторы наших глаз и погрузиться в состояние бессознательного восприятия, при котором на фоне отсутствия привычного процесса повседневного мышления каждая мысль становится восхитительно яркой, как звёздочка в ночном небе и её вспышка и есть то озарение в осознании чего - то возвышенного и не доступного в обычном состояния восприятия. Охватывая всё пространство бессознательного и непроявленного, это день способен сориентировать в самых непонятных ситуациях, а через включение скрытых архивов памяти и полёте неконтролируемой мысли найти выход из любого мудрёного лабиринта.',
    },
    {
        id: 11,
        beastNumber: 11,
        shortDescription: '(Голубая Собака) – Прозрение в малом.',
        title: 'Прозрение в малом и открытие Небес',
        description: 'Это день открываются “Небесные Врата” для связи с божественными истинами и прозрением их. Мы готовы принять и увидеть открывающиеся перед нами возможности правильного пути, подсказываемого нам свыше. Незначительная подсказка способна раскрыть для нас ошибочный выбор и не позволить пойти по обманному пути. В этот день у нас прямая связь с небесами и те, кто сомневается в чём-либо обретают и укрепляют свою Веру. Наши защитники и заступники никогда не забывают нас и в этот день они обязательно проявят себя, чтобы избавить нас от неуместных сомнений в их присутствии. День “Открытых Дверей” и не стоит стучаться в запертые двери если для вас уже есть открытые.',
    },
    {
        id: 12,
        beastNumber: 12,
        shortDescription: '(Голубой Кабан) – Проявление чудес.',
        title: 'Сказочник и звездочёт',
        description: 'Это день оживления сказочного мира в котором сбываются все самые сокровенные наши мечты. Каждый человек родом из детства в котором весь его необъятный мир виртуального безбрежия из самых удивительных чудес. Днём раньше открылась вновь эта дверка в детский мир счастья и фантазии и теперь мы сами становимся хозяевами этого пространства. ы сказочники способные придумать новую сказку или изменить и украсить уже знакомую. В этот день нет ничего невозможного и каждый творец своеей сказк и своей судьбы. Все мечты сбываются, но не все это замечают, потому что иногда выбирают не основное, а мелкое и незначительное.',
    },
    {
        id: 13,
        beastNumber: 13,
        shortDescription: '(Красная Крыса) – Точка кипения.',
        title: 'Точка кипения и искра',
        description: 'Это искра желания и начала сгущения эмоциональности в крови как самой реки Жизни и тела Души в котором вызревают все наши желания. Это начальная точка оживления и начала самой Жизни. Стоит опасаться того, что этот день может проявить себя и как точка закипания, как пик огня и эмоций, которые способны спровоцировать кожные ожоги и даже воспаление лёгких. Очень важно чтобы основной потенциал нашего организма в этот день был направлен в интеллектуальную сферу. Эмоции должны быть под самым жёстким контролем, чтобы не произошло воспламенение, и чтобы из искры не пришёл уже неконтролируемый пожар.',
    },
    {
        id: 14,
        beastNumber: 14,
        shortDescription: '(Красный Волк) – Очищение крови и эмоций.',
        title: 'Очищение крови и эмоций',
        description: 'Это день “Огненного Воина” или “Огненного Меча”. Это один из символов выхода энергии корневого знака каждого человека. Его основная задача - очищение крови и удаление из наших чувств тех, что загрязняют наш организм и получают своеобразную маркировку на удаление пройдя через фильтр крови - “Красную Овцу” (правую почку), с которой этот знак неразрывно связан. В этот день у каждого из нас прогрессирует способность не только ощущать загрязнение своей крови, но и чувствовать этот же процесс в других организмах. Это своеобразная инспекция “Реки Жизни” и всех, с кем ты по ней проплываешь.',
    },
    {
        id: 15,
        beastNumber: 15,
        shortDescription: '(Жёлтый Тигр) – Сбалансированное питание.',
        title: 'Сбалансированное питание',
        description: 'Этот знак называют “Стопою Будды”, след правой стопы оставленный на земле и отлитый в золоте. Это символ срединного пути в земном воплощении. Опора и равновесие заложены в сути этого знака. В этот день происходит гармонизация всех источников питания нашего организма. Древние считали, что любое заболевание можно вылечить правильным подбором питания и потому в этот день необходимо прислушаться к своим желаниям. Тигр связан с желудком и в этот день он самый главный орган в нашем организме, всё внимание ему и все капризы его должны быть удовлетворены. Обязательно будут подсказки того, что нам необходимо для уверенности и устойчивого равновесия в нашей повседневной жизни.',
    },
    {
        id: 16,
        beastNumber: 16,
        shortDescription: '(Жёлтый Кот) – Размышление о равновесии.',
        title: 'Размышление о равновесии',
        description: 'День универсального питания нашей “Головы”. В этот день мы обретаем полный контроль над всеми внутренними распределениями энергий по всем органам нашего организма. Внутреннее равновесие приводит к балансу между телом и эмоциями через контроль разума, поэтому это осознанный процесс подаренный нам природой. Для поддержания этого процесса благоприятно насыщение солнечным световым потоком. В этот день мы сами можем уподобиться данному знаку в его проявлении как “Солнечный Зайчик”, т.е. можем блеснуть золотым светом отражённой Мудрости. Слушайте свои мысли, сегодня они направлены на истинную гармонию в нашем теле.',
    },
    {
        id: 17,
        beastNumber: 17,
        shortDescription: '(Белый Дракон) – Духовность чувствования.',
        title: 'Духовность чувствования и Дух Жизни',
        description: 'Это структурная сетка Сердца, такая же как у металла кристаллическая решётка или снежинка у воды. Это день предельной концентрации чувствительности организм закрывается от приёма всех внешних восприятий и тестирует состояние структурных связей и их нарушений. Если в этот день вы спокойны и сердце не болит, ак в другие дни оно болело, то значит боль приходит извне, а само сердце в полном порядке. В случае каких-либо нарушений происходит предупреждение, либо через сон, либо через повседневные и бытовые ситуации с проявлением смысловых подсказок для защиты целостности и чистоты нашего Сердца.',
    },
    {
        id: 18,
        beastNumber: 18,
        shortDescription: '(Белая Змея) – Закономерность последовательностей.',
        title: 'Закономерность последовательностей и цепочка контроля',
        description: 'Металлическая тонкая лента словно обруч стабилизирует определённый цикл или замыкает как в кандалы источник выхода внутреннего огня. В этот день тестируется состояние всего позвоночного столба и выявляются нарушения, связанные с неправильным распределением огненной энергии “Корневого Знака” - “Кандалини”. Энергия Металла - предельно сжимается, а энергия Огня максимально расширяется, но неразрывная связь их между собой создаёт эффект описанный в сказаниях Догонов “О Бледном карлике и Красном Гиганте”. Чем больше расширение одного, тем сильнее сжатие другого. Каждое звено цепи автономный и замкнутый цикл, при этом они имеют строгую последовательность словно вагоны одного состава или буквы алфавита.',
    },
    {
        id: 19,
        beastNumber: 19,
        shortDescription: '(Чёрная Лошадь) – Глубины непроявленного.',
        title: 'Глубины непроявленного и колодец',
        description: 'В этот день происходит замедление течения внутреннего времени в организме, это схоже с погружением в воду или спуском в колодец. В этот день может ощущаться внутреннее остывание ног до щиколотки, особенно левой ноги и болевая жилка в левом подколенном суставе. Охлаждаясь и замедляясь, наш организм проявляет состояние искусственной слабости и вместе с этим повышенной чувствительности ко всему окружающему его пространству. Не стоит близко к сердцу принимать в этот день незначительные ошибки в поступках близких, которые мы начинаем воспринимать словно через увеличительное стекло.',
    },
    {
        id: 20,
        beastNumber: 20,
        shortDescription: '(Чёрная Овца) – Генетическая наследственность.',
        title: 'Генетическая наследственность',
        description: 'Это день “Наследственной Памяти” или “Наследия Предков” мы соприкасаемся с сакральными процессами в нашем организме, когда происходит раскрытие и пересмотр родовых архивов через канал левой почки с болевым отражением на икре левой ноги. Бытует понятие “Паршивой Овцы”, той что портит всё стадо как самая порочная составляющая. В этот день каждому из нас природа подсказывает самое слабое место в котором мы смертельно уязвимы, но если мы не дадим этому месту проявиться, то сами не будем знать своей основной слабости, а значит не сможем обезопасить свой организм.',
    },
    {
        id: 21,
        beastNumber: 21,
        shortDescription: '(Голубая Обезьяна) – Виртуальное самосохранение.',
        title: 'Крылатый Ангел',
        description: 'Это Ангел Хранитель, которого мы наследуем сразу после появления на свет, как только нам завязывают узел на пупе и отделяют от всего того, с чем мы были связаны до своего рождения. Это первичный уровень нашей самостоятельности- Телесной. Всё с чем мы были связаны и всё что нас окружало до рождения, теперь присутствует в виртуально - энергетическом состоянии возле нас в качестве Защитника и Подсказчика в сложных ситуациях. В это день проявление этого незримого присутствия может стать очевидным и это чаще происходит потому, что мы начинаем утрачивать веру в то, что есть в мире незримые нам силы которые заботяться о нашей безопасности и неотлучно присутствуют возле нас.',
    },
    {
        id: 22,
        beastNumber: 22,
        shortDescription: '(Голубой Петух) – Свободный полёт.',
        title: 'Свободный полёт',
        description: 'В сказках это знак называют “Птицей Счастья” и в этот день нас возвращают в нашу “Небесную Колыбель”, когда все энергии мира словно Сказочные Волшебницы приходят чтобы пожелать нам тех благ, с которыми они связаны. Вспомните сказку о Спящей Красавице и о неприглашённой старой колдунье возле её колыбели.Будьте внимательны в этот день в момент пожеланий этой колдуньи и помните, что ваш “Ангел Хранитель” сразу после этого непременно даст вам противоядие. Этот день - крылья или полёт нашего воображение и нашей фантазии. Возрождение творческого потенциала в пространстве виртуального океана наших природных возможностей утончённого восприятия энергий.',
    },
    {
        id: 23,
        beastNumber: 23,
        shortDescription: '(Красная Собака) – Страстность желаний.',
        title: 'Аленький Цветок',
        description: 'Этот знак мы встречаем в сказке “Аленький Цветок”. По своей сути это день активного генерирования всех видом тонкой энергии в наше тело нашего организма. Красный Цветок - это вершина подъёма красной Змеи Кундалини при котором проявляется восстановление всего тела красной Змеи от самого истока до самой вершины, как главной нити нашей жизненной силы, как фитиля, поддерживающего горение свечи. События этого дня подсказывают нам в каком месте произошло нарушение этого огне-проводящего фитиля и позволяет найти направление разрешения и поправки ситуации как снаружи, так и внутри нас.',
    },
    {
        id: 24,
        beastNumber: 24,
        shortDescription: '(Красный Кабан) – Накопление энергии.',
        title: 'Накопление энергии Огня',
        description: 'День самой активной зарядки аккумулятора нашего тела - Печени. При максимальном протоке крови через этот орган наш организм способен накапливать плазменный запас холодного огня и пускать его внутри нашего тела в образе “Голубой Змеи” - холодного потока энергии который как принц в сказке “Золушка” уже имеет туфельку - “Белого Тигра”, как эталон того, что он должен найти и ради чего обязан спуститься из своего “Дворца” к обычной деревенской “Печке” с золой - “Корневой Чакре” нашего тела. В этот день активируется левое полушарие и усиливается активность прохождения крови через печень. Большая вероятность повышения температуры тела и покраснения глазного яблока, особенно левого.',
    },
    {
        id: 25,
        beastNumber: 25,
        shortDescription: '(Золотая Крыса) – Материализация.',
        title: 'Точка равновесия и Материализации',
        description: 'Это универсальная точка равновесия и гармонизации всех процессов организма через дыхательные практики с использованием ароматических добавок особенно при вдыхании вкусовых запахов пищи. Это своеобразное питание через дыхание при котором вдох и выдох гармонизированы через задержку дыхания на полном вдохе. День благоприятен для выбора режима питания и диеты. В течение всего дня происходит восстановление энергобаланса в организме и “Правый коленный Страх” сигнализирует через ощущения об изменениях в этом процессе. Этот знак связан с точками земли питающими своей энергией все живые организмы и потому рекомендуется в этот день подпитываться из них.\r\n',
    },
    {
        id: 26,
        beastNumber: 26,
        shortDescription: '(Золотой Волк) – Очищение Тела.',
        title: 'Очищение Тела и диетическое питание',
        description: '“Золотой Клинок”. Чистильщик тела и пищи. Контролёр питательной материи, всей пищеварительной системы и универсальности сбалансированной конструкции живых форм. Он реагирует на малейшее изменение гармоничного баланса любого живого тела. Его истинное назначение сохранить землю в её истинной живой чистоте и способности к плодородию. В теле это “Прямая Кишка” через которую из организма  выводится всё что отработано и способно создать осложнения, способствующие токсичному отравлению организма. В этот день обостряются обонятельные реакции на продукты питания и организмы людей в которых проявляются вредоносные или загрязняющие элементы для здоровья и гармоничной жизнедеятельности.',
    },
    {
        id: 27,
        beastNumber: 27,
        shortDescription: '(Белый Тигр) – Духовная пища.',
        title: 'Духовная пища и хранилище Духов',
        description: 'Собиратель и хранитель всех земных Душ. Он способен проявить через себя волю высшей справедливости и Небесного Закона. В его возможности входит обобщение всех первичных структур мироздания, очищение духовно волевого стержня каждой земной Души и потому этот знак называю “Чистилищем Душ”. Один из его характерных образов - “Белая Маска” с пустыми глазницами, как вратами для незримых в материальном мире душ, которые подобно пчёлам или инопланетным кораблям прилетают из своего улья. В этот день происходит контакт с душами ушедших в иной мир близких людей. Этот день особой практики подпитки самой универсальной энергией Эфира.\r\n',
    },
    {
        id: 28,
        beastNumber: 28,
        shortDescription: '(Белый Кот) – Индивидуальность мышления.',
        title: 'Индивидуальность мышления и полнолуние',
        description: 'Энергетический сгусток шарообразной формы с практически прозрачной оболочкой, который является эфирным проявлением информационных блоков жизненных форм для проявления их в физическом состоянии на время, обусловленное особенностями их природного назначения. На привычном языке это - “Души” человеческого, животного и растительного наполнения, воплощающиеся в материальной форме для трансформационного продвижения к совершенному состоянию универсального приближения к божественной сущности вселенского бытия. В этот день происходит очищение Души от тяжести материальных привязанностей. Это благоприятное время для чистки и осознанной коррекции совершенных отклонений от своей программы.\r\n',
    },
    {
        id: 29,
        beastNumber: 29,
        shortDescription: '(Чёрный Дракон) – Властелин морей.',
        title: 'Властелин морей и Черномор',
        description: 'Это день проявления истинной мудрости Сердца. Это тот момент замирания нашего сердца, когда оно наполнено не чувством страха, а чутко прислушивается к самым нежным и почти не проявленным явлениям, затаившимся в прошлом времени, памяти или сумраке неведомого пространства. Именно такое состояние и внимание способствуют самым уникальным граням познания неведомого и сокровенного. Замедляя активность сердца мы засыпаем или погружаемся в глубины непознанного где из сумрака глубины вод на просвет проявляется всё, что не могло ранее оказаться даже случайно в поле нашего восприятия.\r\n',
    },
    {
        id: 30,
        beastNumber: 30,
        shortDescription: '(Чёрная Змея) – Повторяемости.',
        title: 'Замкнутый Круг и Повторяемость',
        description: 'Это энергия закольцованного времени, которое принято называть - цикличностью. Благодаря этому природному феномену мы можем возвращаться в прошлое, погружаясь в кладовые нашей виртуальной памяти, оживляя теплотой своих чувств самые яркие эпизоды прожитой жизни. “Чёрная Змея” - это ещё вибрация звука и самой непроявленной энергии Времени. Она способствует реализации повторного перерождения, организуя своей замкнутой и вневременной структурой пространство, называемое “Ковчегом Завета”. В древних преданиях это образ “Великого Змея” - “Хранителя Времени и Мировых Вод”.\r\n',
    },
    {
        id: 31,
        beastNumber: 31,
        shortDescription: '(Голубая Лошадь) – Прозрачность глубин.',
        title: 'Ковчег Завета',
        description: '“Троянский Конь” или “Деревянная Лошадка”. Это время весеннего пробуждения природы от зимнего сна. Это вешние воды, разламывающие скорлупу речного льда процессами таяния и скоростью превращения в своё привычное текучее состояние. Это и нисходящий с небес дождь, пробуждающий своей питательной влагой всё растительное царство природы, и даже слёзы из наших глаз тоже относятся к знаку “Голубой Лошади”. В русских сказках это та самая маленькая лошадка, которая способна на многие чудеса и которая обладает самым огромным потенциалом реализации в мудрых советах и завещаниях. В этот день возможно прозрение новой дороги и перемен Судьбы.',
    },
    {
        id: 32,
        beastNumber: 32,
        shortDescription: '(Голубая Овца) – Отражение.',
        title: 'Утренняя Роса',
        description: '“Воспоминание” или “Утренняя Роса”. Самый тонкий уровень сохранения и сбора информации, которую ещё называют “Зрительной” памятью. Осевой полярностью она связана со знаком “Голубого Волка”, с которым совместно выполняет функции “Антивирусного” очищения с использованием архивных баз для распознавания попыток вредоносного вторжения в организм для редактирования или углублённой архивации. Это день обновления генной памяти на самом тонком уровне его архивации до дуального сжатия структуры. Проявления такого рода способствуют духовной трансформации человека, изменению и коррекции его наследственных программ.\r\n',
    },
    {
        id: 33,
        beastNumber: 33,
        shortDescription: '(Красная Обезьяна) – Смеющаяся маска.',
        title: 'Хранитель Жизни',
        description: 'Это интенсивная функциональность и кровообращение в тканях Перикарда или околосердечной сумки, что позволяет сердцу усиливать свою активность и восстанавливаться после сбоев и нарушений в стандартном режиме работы. Область живота на теле человека отражает состояние активации околосердечной сумки и потому при постоянной перегрузки сердца и повышенном кровяном давлении, живот увеличивает свои размеры. Оборотной стороной этого знака является “Красный Тигр” и эта связь проявляется через внезапное сокращение объёма желудка с рвотной реакцией в случае нашего приближения к местам повышенной опасности для жизни и здоровья организма человека.\r\n',
    },
    {
        id: 34,
        beastNumber: 34,
        shortDescription: '(Красный Петух) – Крылья чувствительности.',
        title: 'Феникс или Жар Птица',
        description: 'это крылья Огня или “Пожар”. В народных сказках это птица, воскресеющаю из пепла - “Феникс” или “Жар Птица”. Это день раскрытия полноты жизненного пространства. В этот день происходит воскрешение нового огня жизни на пепелище прошлого. Это процесс перерождения в новом образе или в новом теле. В организме человека она связана с Жаром и повышением температуры тела, а порою она вспыхивает эмоциональным пламенем. Повышенная активность в такие дни может лишать человека контроля за своими эмоциями и приводить к очень быстрому расходу запаса сил, особенно если человек начинает много говорить.\r\n',
    },
    {
        id: 35,
        beastNumber: 35,
        shortDescription: '(Золотая Собака) – Золотой рассвет.',
        title: 'Золотой рассвет и реинкарнация',
        description: '“Золотой Ключик”. Универсальный Ключ, позволяющий открыть переход в новый мир, в котором гармония является основой всех отношений. Это уникальная возможность в земном воплощении открыть для других путь к новому видению и построению природы в повседневной обыденности и этим осуществить чудо внутреннего перерождения индивидуального мира человека. Сам же знак неразрывно связан со знаком “Золотого Дракона”, который проявляет собой “Умиротворение” и полную гармонию в земном воплощении. В этот день мы можем получить универсальное подключение к гармонизации противоречий, которые терзают нас и не дают покоя нашему Сердцу. Причиной такого чуда может стать встреча с человеком, который откроет в нас истинную суть духовного намерения.\r\n',
    },
    {
        id: 36,
        beastNumber: 36,
        shortDescription: '(Золотой Кабан) – Милосердие.',
        title: 'Милосердие и энергия питания',
        description: '“Копилка”. “Золотая Чушка” - это и отлитый слиток золота, и сосуд для аккумуляции универсального энергетического резерва - “Золотого Запаса”. Знак способствует гармонизации в эмоциональной сфере и возможности сочетания различных эмоций для взаимного баланса. В храме есть место имеющее прямое отношение к этому знаку - “Золотые Врата”. В этот день каждый из нас способен оценить то богатство, которое мы можем обрести на земле, и которое многие считают материальным, но на самом деле оно способно изменить нас свои неиссякаемым энергетическим потенциалом, проявляющимся через гармонию со всей природой.\r\n',
    },
    {
        id: 37,
        beastNumber: 37,
        shortDescription: '(Белая Крыса) – Проницаемость.',
        title: 'Проницаемость и косность',
        description: 'Это день обострённой концентраци духовного пространства, как свечение на кончике иглы при избыточном статическом заряде в предгрозовой атмосфере. Это полный предел сжатия и замирания всех уровней активности. Он как оболочка скорлупы яйца и ореха, или любого семени. Это кристалл в размер своего первичного блока кристаллической решётки. Это и игла со смертью “Кощея”, и любое остриё способное пронзить и проникнуть. В этот день, как ни в какой другой, мы способны считывать из окружающей среды самые утончённые вибрации и даже чувствовать мысли близких людей. Это знак связан с духовным очищением через медитативные и дыхательные практики, а связанная с ним поверхность кожного покрова концентрирует всю структуру энергетических каналов словно на единой микросхеме.\r\n',
    },
    {
        id: 38,
        beastNumber: 38,
        shortDescription: '(Белый Волк) – Очищение Духа.',
        title: 'Меч духовного очищения',
        description: '“Меч Истины”. Это седой и бородатый старец, пребывающий в состоянии самоуглубления и изучения - “Чистильщик Духа”. Это тот Воин, который заострён на чистоту своих помыслов и всего внутреннего пространства. Он как изгой и аскет в среде своих серых собратьев, отрешённый от повседневности постоянным изучением структур духовного кристалла своего земного воплощения. Это самый остов всех уровней очищения и просветления - вожак всех чистильщиков для других стихий. В сказках - это меч “Кладенец”, который скрыт и обращён внутрь, в свою рукоять и способный проявиться через свой Духовный стержень в виде огня, в виде льда, и в виде луча ясного солнечного света.',
    },
    {
        id: 39,
        beastNumber: 39,
        shortDescription: '(Чёрный Тигр) – Скрытый след.',
        title: 'Скрытый след и аскетизм',
        description: '“Скрытый След”. В этот день  очень важную роль играют сновидения. Сам знак как невидимая материя или незримая форма не проявляет своего присутствия и не привязан к материальной сфере потребления. В основе его питания - звук, как сакральная вибрация Знаний  из планетарных архивов Земли. Соприкосновение с этими знаниями проявляется в основном во время Сна. Для усвоения и принятия такой информации необходима специальная подготовка и настройка диапазона пространственного восприятия мышления в виртуальных формах и объёмных символах. Во сне его можно увидеть, как в образе “Чёрной Пантеры”, так и “Чёрного Камня” с рисунками и символами.',
    },
    {
        id: 40,
        beastNumber: 40,
        shortDescription: '(Чёрный Кот) – Проводник во тьме.',
        title: 'Проводник во тьме и Тайное Знание',
        description: '“Сталкер”, “Чёрный Шар” сакральной мудрости, которая способна вывести человека из самых тёмных и опасных уголков уголков заблуждений и подсказать подсознанию огонёк надежды, который выведет из запутанности лабиринтов на свет к ясному восприятию самого тёмного явления. У Конфуция, как и у каждого мудреца, была своя визитная карточка, проявленная притчей. В ней он рассказывал о том, что трудно найти чёрную кошку в чёрной комнате. особенно если её там нет. Он сам и был этой мудрой кошкой, давая возможность своими подсказками перейти ограниченному стандартным мышлением человеку, на расширенный уровень восприятия, через понимание, что чёрной кошкой в следующем масштабе смыслового понимания уже является сама “Тёмная Комната”\r\n',
    },
    {
        id: 41,
        beastNumber: 41,
        shortDescription: '(Голубой Дракон) – Зрячее сердце.',
        title: 'Зрячее сердце и Всеведение',
        description: '“Зрячее Сердце”. Своеобразное восприятие потока информации виртуальных вибраций пространства через считывание их ощущениями. Это явление называли “Прозрение или видение Сердцем”. Иногда можно услышать понятие “Третьего Глаза” или “Всевидящего Ока”, это всё тот же знак. Физиологической особенностью такого природного феномена является открытие овального клапана сердца, который находится между правым и левым предсердиями. В момент его открытия происходит резкое увеличение восприятия ёмкости информационного потока через кровоток обогащённый кислородом и одновременно с этим оба полушария головного мозга человека включаются в совместную работу.\r\n',
    },
    {
        id: 42,
        beastNumber: 42,
        shortDescription: '(Голубая Змея) – Небесная связь.',
        title: 'Небесная связь и Откровение Свыше',
        description: '“Воздушный Змей”. Это тончайшая частота вибраций для передачи импульсов воспроизведения и передачи потоков образной информации. В организме человека этот знак отражает как тончайший нерв, так и всю сеть нервной системы человека. Такая же сетка заполняет и всё окружающее нас пространство, позволяя нам подсознательно принимать информацию, ещё не остывшую в твёрдой материи с опережением во времени, что мы называем “Предвиденьем”. Это день тонкой энергетической нить мысли, из которой ткётся всё пространство нашего мира воображения, сказочного и необъятного. Это связь этого знака со знаком “Голубого Кабана” - “Волшебника”, “Сказочника” и “Звездочёта”.\r\n',
    },
    {
        id: 43,
        beastNumber: 43,
        shortDescription: '(Красная Лошадь) – Река Жизни.',
        title: 'Река Жизни и Тело Души',
        description: '“Огненная Жидкость” или “Кровь”. Всякая горячая, горючая, красная и просто цветная жидкости относятся к этому знаку. Она оживляет своим потенциалом многие процессы и жизненные явления. Это своеобразная “Река Жизни”, это кровь в нашем теле ускоряющая и придающая жизни все краски яркого восприятия и памяти. Кровь является телом Души и в ней оживают все наши чувства и эмоции. В этот день нужно понаблюдать за своей эмоциональностью и по ней можно будет понять в каком состоянии наша кровь и здоровье всего нашего организма. Контролируя свои отрицательные эмоции мы не даём своей крови загрязняться и способствуем быстрому восстановлению функциональности всей кровеносной системы.\r\n',
    },
    {
        id: 44,
        beastNumber: 44,
        shortDescription: '(Красная Овца) – Дарующий Жизнь.',
        title: 'Дарующая Жизнь и Фильтрующая Кровь',
        description: '“Память Прожитых Жизней” или “Канал оживления Рода”. Это врата перехода в новую жизнь. Правая почка - “Ворота Жизненности”. В её функции входит оживление канала для реализации генетической памяти при зачатии новой жизни в “Ковчеге” материнского тело. Помимо этого, сам знак, как и почка не только фильтрует кровь, но и способствует увеличению в крови информационной ёмкости за счёт стимуляции эмоциональности. В этот день следует обратить внимание на возможный процесс похожий на закипание крови и обжигающие ощущения на левой руке выше запястья. Это процесс совместного включения с этим знаком его крытой половины “Красного Волка” - “Чистильщика Крови”.',
    },
    {
        id: 45,
        beastNumber: 45,
        shortDescription: '(Золотая Обезьяна) – Улыбка.',
        title: 'Хануман и Хранитель Бессмертной Любви',
        description: '“Хануман”. В восточных мифах это “Сын Бога Ветра” и “Король Обезьян”. Это природная способность изменения и гармонизации физических параметров материальных форм для эффективной защиты всего живого  в проявленном пространстве. Универсальная возможность использования различных доступных ресурсов для поддержания в гармонии всех форм жизни на земле. В этот день следует отмечать особо ощущения, получаемые через солнечное сплетение и выявлять связи этих внутренних ощущений с возникающими ситуациями и поступки через окружающих вас людей. В этот день происходит активная подпитка тела энергией земли через поверхность правой стопы.',
    },
    {
        id: 46,
        beastNumber: 46,
        shortDescription: '(Золотой Петух) – Предупреждение.',
        title: 'Предупреждение и Жизненное пространство',
        description: 'Это день в который мы можем получить предупреждение о приближении опасности в ближайшие 60 дней, с подсказкой в миниатюрной форме какого плана эта опасность будет пытаться проявиться. Внимательное восприятие происходящего даст фокусировку на такое предупреждение, и мы сможем подготовиться к грядущему или изменим обстоятельства не создав условий и в этот день и для проявления нежелательного или даже опасного результата в дальнейшем. В сказке Пушкина “О Золотов Петушке” достаточно подсказок на эту тему, нужно лишь вникнуть в смысловое пространство текстового изложения и понять то откровение, которое оставлено для нас автором.',
    },
    {
        id: 47,
        beastNumber: 47,
        shortDescription: '(Белая Собака) – Оруженосец.',
        title: 'Оруженосец и Оружейник',
        description: 'День проявления Духовной поддержки. Мы можем в этот день получить подсказку и главное вооружиться средствами активной защиты от явлений, обстоятельств и конкретных персонажей внешнего мир, представляющих опасность. Порой нам для такой защиты не хватает проявления жёсткого волевого стержня как уверенности в собственных силах. В этот день проявляется программа сбора наследуемых родительских данных для передачи потомству. Знак «Собаки» связан с детородными функциями и потому во всех религиях  она выступает в качестве стража ворот в иной мир, а в её образе перед нами может проявиться Дух ребёнка готового к рождению в нашем мире.',
    },
    {
        id: 48,
        beastNumber: 48,
        shortDescription: '(Белый Кабан) – Стратегия защиты.',
        title: 'Источник энергетического Очищения',
        description: 'Рациональное расходование сил и энергетического потенциала нашего организма полностью зависит от структурной целостности статических накопителей эфирного заряда. Потому как знак «Кабана» - аккумулятор всех энергий в нашем организме, то, как и у автомобильного аккумулятора у него те же функции и те же основы для бесперебойной работы. Аккумуляторная батарея в автомобиле служит для питания электрическим током всех потребителей, когда двигатель работает на холостом ходу без движения. Аналогичную роль в нашем организме выполняет «Кабан» и его орган - Печень.\r\nСвоим зарядом он стимулирует «мотор» нашего организма - Сердце и все нуждающиеся в его энергии органы и системы.',
    },
    {
        id: 49,
        beastNumber: 49,
        shortDescription: '(Чёрная Крыса) – Забвение.',
        title: 'Забвение и Точка Замерзания',
        description: 'День остановки или прерывания. Его называют «Мёртвой Точкой» которая одновременно и «Точка возврата или невозврата». В этот день есть реальная возможность перешагнуть через черту возврата назад, как самолёту вернуться на тот аэродром с которого он вылетел и только в этой точке у него ещё достаточно топлива, чтобы повернуть обратно, но пересекая эту точку уже нет возможности к возврату и к тому что было раньше. Разрыв требует обновления и это самый удачный день чтобы оставить что-то за спиной и двинуться к новым берегам. В этот день обязательно происходит это заложенное природой явление, а мы не всегда способны разглядеть это и до конца осмыслить в чём мы так и не смогли перешагнуть эту точку прерывания, и что же начало обновляться с этого момента.',
    },
    {
        id: 50,
        beastNumber: 50,
        shortDescription: '(Чёрный Волк) – Путешествие во времени.',
        title: 'Путешествие во Времени и Воин Невидимка',
        description: 'Волк всегда «Воин», а воин - это оружие которым он владеет. В данном случае и сам воин, и его оружие скрыто от восприятия. Невидимка способный исчезать и переходить по энергетическим каналам скрытых потоков времени. Возможностями этого воина является чистка и изменение русла самого времени, его скорости и даже разделение во времени находящихся рядом людей или предметов.\r\nОн способен заблокировать даже генные проявления наших унаследованных архивов памяти расслоением спирали ДНК, и удалить выделенное в карантин парного с собой знака Чёрной Овцы. В каждом человеке есть полный набор всех 60 знаков, но основные 10 проявлены в его «Древе Жизни», а в этот день обстоятельства резонируют к проявлению в нас «Скрытого Воина» - «Ниндзя».',
    },
    {
        id: 51,
        beastNumber: 51,
        shortDescription: '(Голубой Тигр) – Мир глазами пчелы.',
        title: 'Мир глазами Пчелы и Время Молитвы',
        description: 'По славянским легендам, создавая мир живых существ, творец лишь одно существо не погрузил во «Тьму», чтобы не прерывать связь с сотворённым миром и им была «Пчела». Это аналог светового импульса, исходящего от самого большого светила для поддержания жизни в нашем мире. Он наполняет жидкость солнечным запасом световой энергии, а при необходимости может ужалить придерживая нас в рамках законов живой природы. Пчела никогда не спит. В этот день всё живое соединяется со своим творцом, чтобы восстановиться и скорректировать свои поддерживающие жизнь функции. Это самое благоприятное время для молитвенных обращений и связи с всевышними силами.',
    },
    {
        id: 52,
        beastNumber: 52,
        shortDescription: '(Голубой Кот) – Энергия Воображения.',
        title: 'Энергия Воображения и Шаровая Молния',
        description: '«Улей» проявляется сочетанием сразу двух знаков в единую семью «Голубого Тигра» и «Голубого Кота». В день «Голубого Кота» или виртуального мыслительного пространства происходит тестирование нашей интеллектуальной совместимости с Божественным интернетом, который был самым первым у самой колыбели сотворения всего живого на Земле и самого человека тоже. Передача тестовых информационных потоков происходит в виде непрерывного ряда образов взаимно проникающих в диапазоне предельно утончённого восприятия. Это тестирование доступно для каждого человека, но воспринять его и пройти могут лишь те, кто сознательно вышел из рамок стандартной чувствительности к окружающему его миру.',
    },
    {
        id: 53,
        beastNumber: 53,
        shortDescription: '(Красный Дракон) – Пламя чувств.',
        title: 'Пламя чувств и Радость Жизни',
        description: 'Это день проявления всей полноты «Огня Сердца» и состояния Души. Весь внутренний огонь в нашем теле концентрируется в сердечной области и в этот день с нами обязательно стремиться пообщаться тот, кто является частичкой этого огня чувств. События, происходящие вокруг нас и наше физическое здоровье отражают состояние нашей Души с её слабостями и пробелами. В этот день Огонь нашей Души помогает нам узнать на сколько мы истощили или наполнили свою Душу желаниями жить и созидать или выплёскивали её драгоценное тепло вокруг себя проявляя пламя обжигающее и других и себя тоже. Это тест на целостность нашей Души. Не пропустить холод безразличия в Сердце и не выдохнуть из себя пламя как из пасти огнедышащего Дракона.',
    },
    {
        id: 54,
        beastNumber: 54,
        shortDescription: '(Красная Змея) – Кровные узы.',
        title: 'Кровные Узы и Кровеносная система',
        description: 'После концентрации Огня в Сердце начинается обратный процесс растекания Огня по кровеносным сосудам до самых отдалённых уголков нашего организма. Кровеносную систему называют еще «Большим Сердцем», потому как она в увеличенном масштабе воспроизводит все процессы, происходящие в нашем сердце. Выходя из границ предельного сжатия - сердца, огонь начинает процесс своего расширения по кровеносным сосудам, а во внешнем пространстве это проявление кровных или чувственных связей. «Красная Змея» это ещё и «Кундалини», которая подобно раскаляющейся спирали поднимается из области первой чакры, находящейся в копчике позвоночного столба, и раскрывающая потенциал жизненных сил, а через него и сексуальности.',
    },
    {
        id: 55,
        beastNumber: 55,
        shortDescription: '(Золотая Лошадь) – Истоки Рода.',
        title: 'Истоки Рода и Золотая Чаша',
        description: 'Этот день называют «Золотой Рекой», «Руслом реки Времени» или «Ковчегом Завета». Все события этого дня настроены на регулирование до гармоничного состояния двух потоков Времени - внутри нашего тела и снаружи. Наше тело становится подобным чаше в которой должно быть красной жидкости ровно столько, чтобы звук воспроизводимый с её поверхности резонировал со звуком окружающего пространства. Если теперь уточнить в реальном аспекте понимания, то в этот день каждый контакт с вами позволит прислушаться к проявленной от него внутренней реакции тела и настроить на резонанс общения через изменение своего эмоционального уровня красного вина - Крови, как тела нашей Души или объёма присутствия Души в Теле.',
    },
    {
        id: 56,
        beastNumber: 56,
        shortDescription: '(Золотая Овца) – Песок Времени.',
        title: 'Песок Времени и Золотое Руно',
        description: 'Этот день обладает особыми свойствами, потому что является теми песочными часами у которых на этот момент песка времени ровно на полпути до полного переворота и начала нового отсчёта. Именно это может позволить нам волевым усилием создать движение самих часов до половинного их поворота при котором они замрут в горизонтальном положении за счёт равновесия песка в обеих ёмкостях, и их форма «восьмёрки» превратится в знак «бесконечности», а время остановит своё течение. В таком положении происходит выравнивание баланса нашей генной наследственности и как следствие этого восстанавливается и гармонизируется функциональность почек. Именно в такой момент происходит спонтанное открытие «Третьего Глаза».',
    },
    {
        id: 57,
        beastNumber: 57,
        shortDescription: '(Белая Обезьяна) –  Дыхательная практика.',
        title: 'Дыхательная практика и Защита Духовности',
        description: 'Проявление этого символа как в снах, так и наяву можем быть в образе «Женщины в Белом». Её функция в том, чтобы обезопасить наш Дух и предупредить об проявлениях опасности для его чистоты и целостности. Дух человека проявляется в его Воле и стремлении выполнить программу, вложенную в каждое тело самой Природой. Есть программа обязательная связанная всеобщими задачами совместно со всеми живыми организмами для поддержания самой жизни на нашей планете она заложена у нас в «Ковчеге». Только после выполнения обязательной программы мы можем приступить к выполнению «Произвольной» или «Индивидуальной» которая связана у нас со знаком «Головы», а он не что иное как «Семя нового Перерождения» на нашем «Древе Жизни».',
    },
    {
        id: 58,
        beastNumber: 58,
        shortDescription: '(Белый Петух) – Очищение свыше.',
        title: 'Очищение Свыше и Дух Святой',
        description: 'Энергия белого цвета стихии «Металла» связана с чистотой и Духовностью. В этот день происходит очищение «крыльев» нашего мышления и воображения. Именно поэтому символ Чистой или Белоснежной Птицы издревле связывался со «Святым Духом». Чистота восприятия, как энергетическое питание всего организма, способна изменить на самом тонком уровне с самого основания всю «кристаллическую» структуру обновления нашего земного тела через преображение нашей эмоциональной сферы и соответственно самого здоровья человека. Это день причащения и исповедания, день пробуждения Совести. Любой, даже самый незначительный негатив спрятанный в глубинах нашей памяти постоянно и незримо отравляет нас изнутри и его нужно нейтрализовать.',
    },
    {
        id: 59,
        beastNumber: 59,
        shortDescription: '(Чёрная Собака) – Хранитель времени.',
        title: 'Хранитель Времени и Граница Пространства',
        description: 'Как и во все дни энергии «Воды» одно и то же явление проявляется двойственно и полярно, но при этом суть его остаётся неизменной. В этот день образ появившейся «Чёрной Собаки» - это предупреждение нам о приближении к границе с потусторонним миром. В повседневной жизни может проявиться неизвестный нам человек и предупредит нас как друг об опасности. В полярном варианте самый близкий человек или друг может проявить себя негативно по отношению к нам, и очень важно понять, что его поведение и действия направлены только на одно, чтобы даже ценой вашей дружбы остановить не видимую рядом опасность, и чтобы отвести от нас беду.',
    },
    {
        id: 60,
        beastNumber: 60,
        shortDescription: '(Чёрный Кабан) – Перезагрузка.',
        title: 'Перезагрузка и Отшельничество',
        description: 'Этот знак называют «Слепым» потому что являясь замыкающим в календарном круге, где он должен завершить круг предыдущий, чтобы смог начаться круг, следующий. В этом вся простота и суть природных превращений в которых завершённость - основное условие обновления. Этот день называют «Днём решения Проблем». Когда компьютер выключается для перезагрузки, он проявляет нам всё, что мы за этот период в 60 дней начинали, но так и не довели до конечного результата и потому должны принять решение как завершить начатое, сохранить на продолжение в новом круге, завершить или удалить как не заслуживающее внимания.',
    },
]
