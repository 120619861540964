import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useSound from 'use-sound';
import { authSelector } from '../../../redux/modules/auth/auth.selectors';
import { LogoText } from '../../components/LogoText';
import { AppBar } from './NavbarElements';
import { HideOnScroll } from '../../../components/HideOnScroll';
import { ColorModeContext } from '../../../helpers/theme';

interface INavbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  handleLogout: () => void;
}

function Navbar(props: INavbarProps) {
  const { open, handleDrawerOpen, handleDrawerClose, handleLogout } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useSelector(authSelector);
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const [playOn] = useSound(
    '/sounds/switch-off.mp3',
    { volume: 0.25, interrupt: true },
  );
  const [playOff] = useSound(
    '/sounds/switch-on.mp3',
    { volume: 0.25, interrupt: true },
  );

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  const toggleColorMode = useCallback(() => {
    if (theme.palette.mode === 'dark') {
      playOff();
    } else {
      playOn();
    }
    colorMode.toggleColorMode();
  }, [theme.palette.mode]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    // navigate(locations.myProfile());
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>Профиль</MenuItem>
      <MenuItem onClick={handleLogout}>Выйти</MenuItem>
    </Menu>
  );

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" open={open} elevation={0}>
          <Toolbar
            sx={{
              padding: '28px 32px!important',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            component={Grid}
          >
            <Grid item display="flex" justifyContent="center" alignItems="center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                sx={open ? { ml: 0 } : { ml: '96px' }}
                edge="start"
              >
                <MenuIcon sx={{ fontSize: '24px' }} />
              </IconButton>
              <LogoText />
            </Grid>
            <Grid item>
              <IconButton sx={{ mr: 1 }} onClick={toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user?.name
                    .split(' ')
                    .slice(0, 2)
                    .map((i: string) => i.charAt(0))
                    .join('')}
                </Avatar>
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMenu}
    </>
  );
}

export default Navbar;
