import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import styled from '@mui/system/styled';
import React from 'react';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: (theme.typography as any).pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
