import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';

export const authSelector = ({ auth }: ReducerState) => auth;
export const authUserSelector = createDraftSafeSelector(authSelector, ({ user }) => user);
export const authLoadingSelector = createSelector(
  authSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
