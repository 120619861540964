import * as yup from 'yup';

export const passwordSchema = yup.string().required('Пароль обязателен').trim().strongPassword({
  minLengthMessage: 'Минимальная длинна пароля 8 символов',
  maxLengthMessage: 'Максимальная длинна пароля 32 символа',
  lowerCaseMessage: 'Должен содержать строчную букву',
  consecutiveMessage: 'Не должно содержать 3 или более последовательных одинаковых символов',
  sequentialMessage: 'Не должно содержать 3 или более последовательных символов',
  upperCaseMessage: 'Должен содержать заглавную букву',
  digitMessage: 'Должен содержать цифру',
  specialMessage: 'Должен содержать смец. символ',
});
export const secretPasswordSchema = yup.string().required('Пароль обязателен').trim().strongPassword({
  minLengthMessage: 'Плохой пароль',
  maxLengthMessage: 'Плохой пароль',
  lowerCaseMessage: 'Плохой пароль',
  consecutiveMessage: 'Плохой пароль',
  sequentialMessage: 'Плохой пароль',
  upperCaseMessage: 'Плохой пароль',
  digitMessage: 'Плохой пароль',
  specialMessage: 'Плохой пароль',
});

export const passwordConfirmationSchema = yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать');
