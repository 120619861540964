import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import HistoryIcon from '@mui/icons-material/History';
import CssBaseline from '@mui/material/CssBaseline';
import { Autologin } from '../../components/Autologin';
import { useAppDispatch } from '../../redux/hooks';
import { logout } from '../../redux/modules/auth/auth.actions';
import { locations } from '../../routes/locations';
import { parseJSON } from '../../utils/json';
import { MobileNavbar } from './Navbar/MobileNavbar';
import Navbar from './Navbar';
import { Sidebar } from '../components';
import { DrawerHeader } from '../components/Sidebar/SidebarElements';
import { MenuItems } from '../components/Sidebar/types';

export default function AppPageLayout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(parseJSON(localStorage.getItem('menuOpen') || 'false'));
  const isDark = theme.palette.mode === 'dark';

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate(locations.login(), { replace: true, state: { from: location.pathname } });
  }, []);

  useEffect(() => {
    const onNotAuthorized = (event: MessageEvent) => {
      if (event.data === 'NotAuthorized') {
        handleLogout();
      }
    };

    window.addEventListener('message', onNotAuthorized, false);

    return () => {
      window.removeEventListener('message', onNotAuthorized, false);
    };
  }, [location]);

  const handleDrawerOpen = useCallback(() => {
    localStorage.setItem('menuOpen', 'true');
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    localStorage.setItem('menuOpen', 'false');
    setOpen(false);
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const menuItems = useRef<MenuItems>([
    [
      {
        title: 'Главная',
        IconComponent: () => <DashboardIcon />,
        path: locations.dashboard(),
      },
      {
        title: 'Сетка',
        IconComponent: () => <ViewCompactIcon />,
        path: locations.calendarGrid(),
      },
      {
        title: 'Календарь',
        IconComponent: () => <CalendarMonthIcon />,
        path: locations.personalCalendar(),
      },
      {
        title: 'История',
        IconComponent: () => <HistoryIcon />,
        path: locations.history(),
      },
      {
        title: 'О Нас',
        IconComponent: () => <InfoIcon />,
        path: locations.about(),
      },
    ],
    [
      {
        title: 'Выйти',
        IconComponent: () => (
          <LogoutIcon sx={{ transform: 'rotate(180deg)' }} />
        ),
        onClick: () => handleLogout(),
      },
    ],
  ]);
  /* eslint-enable react/no-unstable-nested-components */

  return (
    <>
      {!matchMdAndAboveView && (
        <MobileNavbar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          handleLogout={handleLogout}
          menuItems={menuItems.current}
        />
      )}
      <Autologin>
        <Box sx={{ display: 'flex', pb: 15, minHeight: '100vh', filter: isDark ? 'brightness(0.5)' : '' }}>
          <CssBaseline />
          {matchMdAndAboveView && (
            <>
              <Navbar
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleLogout={handleLogout}
              />
              <Sidebar
                menuItems={menuItems.current}
                open={open}
                handleDrawerClose={handleDrawerClose}
              />
            </>
          )}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              m: 0,
              p: 0,
              pt: 2,
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            <DrawerHeader />
            <Outlet />
          </Box>
        </Box>
      </Autologin>
    </>
  );
}
