import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { BiorhythmType } from '../../constants/biorhythm';
import { calculateBiorhythm } from '../../helpers/biorhythm';
import { round2 } from '../../utils/number';
import { TabPanel } from '../Tabs';
import { BiorhythmsChart, ChartDataSetItem } from './BiorhythmsChart';
import { BiorhythmsList } from './BiorhythmsList';

interface BiorhythmsProps {
  date: string;
  liveDays: number;
  autoUpdate: boolean;
}

interface DataSetItem {
  date: string;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Biorhythms: React.FC<BiorhythmsProps> = (props) => {
  const { date, autoUpdate, liveDays } = props;

  const [numOfDays, setNumOfDays] = useState(100);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [physicalBiorhythms, setPhysicalBiorhythms] = useState<DataSetItem[]>([]);
  const [emotionalBiorhythms, setEmotionalBiorhythms] = useState<DataSetItem[]>([]);
  const [intellectualBiorhythms, setIntellectualBiorhythms] = useState<DataSetItem[]>([]);
  const [biorhythms, setBiorhythms] = useState<ChartDataSetItem[]>([]);

  const calculateBiorhythms = useCallback((tDays) => {
    const physical = round2(calculateBiorhythm(tDays, BiorhythmType.Physical));
    const emotional = round2(calculateBiorhythm(tDays, BiorhythmType.Emotional));
    const intellectual = round2(calculateBiorhythm(tDays, BiorhythmType.Intellectual));

    return {
      physical,
      emotional,
      intellectual,
    };
  }, [liveDays]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const calculateForNDays = () => {
    const currentDate = moment(date, 'YYYY-MM-DD');

    const physicalItems: DataSetItem[] = [];
    const emotionalItems: DataSetItem[] = [];
    const intellectualItems: DataSetItem[] = [];
    const biorhythmItems: ChartDataSetItem[] = [];
    // const newFullCrashDays: string[] = [];

    let tDays = liveDays;
    let index = 0;

    do {
      const { physical, emotional, intellectual } = calculateBiorhythms(tDays);

      physicalItems.push({ date: currentDate.format('DD.MM.YYYY'), value: physical });
      emotionalItems.push({ date: currentDate.format('DD.MM.YYYY'), value: emotional });
      intellectualItems.push({ date: currentDate.format('DD.MM.YYYY'), value: intellectual });

      biorhythmItems.push({
        date: currentDate.format('DD.MM.YY'),
        physical,
        emotional,
        intellectual,
      });

      index++;
      tDays++;
      currentDate.add(1, 'days');
    } while (index < numOfDays);

    setPhysicalBiorhythms(physicalItems);
    setEmotionalBiorhythms(emotionalItems);
    setIntellectualBiorhythms(intellectualItems);
    setBiorhythms(biorhythmItems);
  };

  useEffect(() => {
    if (autoUpdate) {
      calculateForNDays();
    }
  }, [liveDays, numOfDays, autoUpdate]);

  return (
    <Grid
      item
      container
      flexDirection="column"
      rowSpacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      <Grid item container columnSpacing={1} justifyContent="center" alignItems="center">
        <Grid item textAlign="right">
          Рассчитать на
        </Grid>
        <Grid item>
          <TextField
            id="number-of-days-to-calc"
            type="number"
            margin="none"
            size="small"
            value={numOfDays}
            onChange={(e) => {
              if (!Number.isNaN(+e.target.value)) {
                setNumOfDays(+e.target.value);
              }
            }}
            inputProps={{ step: 100, min: 100, max: 1000 }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item textAlign="left">
          дней вперед.
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="График" {...a11yProps(0)} />
              <Tab label="Список" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <BiorhythmsChart data={biorhythms} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <BiorhythmsList data={biorhythms} />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};
