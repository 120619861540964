import Dialog from '@mui/material/Dialog';
import styled from '@mui/system/styled';

export const StyledDialog = styled(Dialog)(({ theme, color }) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      borderRadius: 5,
    },

    '& .MuiDialogTitle-root': {
      backgroundColor: color,
    },

    '& .MuiDialogContent-root': {
      paddingTop: theme.spacing(5),
      borderTop: '1px solid white',
      backgroundColor: color,
    },

    '& .MuiDialogActions-root': {
      backgroundColor: color,
    },
  },
}));
