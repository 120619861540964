import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IAsyncDialogProps } from '../AsyncDialog/AsyncDialog';

export interface ConfirmationDialogData {
  text: string;
}

export function ConfirmationDialog(props: IAsyncDialogProps<boolean, void, ConfirmationDialogData>) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>Подтверждение операции</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.data?.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={() => props.onClose()}>Нет</Button>
        <Button variant="contained" color="primary" onClick={() => props.onOk(true)}>Да</Button>
      </DialogActions>
    </Dialog>
  );
}
