import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import _chunk from 'lodash/chunk';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { getHeadBeast } from '../../helpers/calendar';
import { configSelector } from '../../redux/modules/config/config.selectors';
import { BeastInfo } from '../../utils/calendar';

export type CalendarGridYearDayInfo = BeastInfo & { date: Moment };

interface CalendarGridYearProps {
  year?: number;
  onDayClick: (date: CalendarGridYearDayInfo) => void;
}

export const CalendarGridYear = memo((props: CalendarGridYearProps) => {
  const theme = useTheme();
  const { year = new Date().getFullYear(), onDayClick } = props;
  const isDark = theme.palette.mode === 'dark';

  const { colors, fontColors, beasts, numberBeasts, dateNull: sysDateNull } = useSelector(configSelector);
  const [days, setDays] = useState<CalendarGridYearDayInfo[]>([]);
  const [chunks, setChunks] = useState<CalendarGridYearDayInfo[][]>([]);

  useEffect(() => {
    const startDateOfTheYear = moment([year]).startOf('year');
    const endDateOfTheYear = moment([year]).endOf('year');

    const headBeast = getHeadBeast(numberBeasts, startDateOfTheYear, sysDateNull);

    const newDays: CalendarGridYearDayInfo[] = new Array(headBeast.beastNumber - 1).fill(1).map((item, index) => {
      const beastId = (index) % 12;
      const colorId = (index) % 10;
      const beastNumber = numberBeasts[beastId][Math.floor(colorId / 2)];

      return {
        date: startDateOfTheYear.clone().add(index - headBeast.beastNumber, 'days'),
        beastId,
        colorId,
        beastNumber,
      };
    }).concat({ date: moment(startDateOfTheYear), ...headBeast });

    let iteration = 0;

    do {
      const numDays = (iteration + headBeast.beastNumber) % 60;
      const beastId = numDays % 12;
      const colorId = numDays % 10;
      const beastNumber = numberBeasts[beastId][Math.floor(colorId / 2)];

      startDateOfTheYear.add(1, 'days');

      newDays.push({
        date: moment(startDateOfTheYear),
        beastId,
        colorId,
        beastNumber,
      });

      iteration++;
    } while (startDateOfTheYear.year() === endDateOfTheYear.year() || newDays.length % 12 !== 0);

    setDays(newDays);
    setChunks(_chunk(newDays, 60));
  }, [colors, beasts, year]);

  const renderGridItem = (data: CalendarGridYearDayInfo, content: string, isButton: boolean) => {
    const weekDay = data.date.weekday();

    return (
      <Grid
        item
        key={data.date.toISOString()}
        xs={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        component={Paper}
        elevation={2}
        sx={{
          border: '2px solid black',
          color: fontColors[colors[data.colorId]],
          backgroundColor: colors[data.colorId],
          height: '40px',
          width: '100%',
        }}
      >
        {isButton ? (
          <Button
            onClick={() => onDayClick(data)}
            sx={{ color: fontColors[colors[data.colorId]], width: '100%', height: '100%' }}
          >
            {[6, 0].includes(weekDay) && (
              <Grid
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: weekDay === 6 ? 'yellow' : 'white',
                  borderRadius: '50%',
                  position: 'absolute',
                  border: '1px solid black',
                  top: 3,
                  right: weekDay === 6 ? 3 : undefined,
                  left: weekDay === 6 ? undefined : 3,
                }}
                title={weekDay === 6 ? 'Воскресенье' : 'Понедельник'}
              >
                &nbsp;
              </Grid>
            )}
            <Typography component="pre" fontWeight="bold">
              {content}
            </Typography>
          </Button>
        ) : (
          <Typography component="pre" fontWeight="bold">
            {content}
          </Typography>
        )}
      </Grid>
    );
  };

  const getGroupKey = (index: number) => `group-${index}`;

  return (
    <Grid
      container
      flexDirection="row"
      id="calendar-grid-year"
      className={isDark ? 'calendar-grid-container--dark' : 'calendar-grid-container'}
      sx={{ minWidth: '700px' }}
    >
      <Grid
        item
        container
        sx={{
          backgroundColor: '#2a6eca',
          border: '2px solid transparent',
          borderBottomWidth: '4px',
          borderTopWidth: '4px',
        }}
      >
        {beasts.map((beast, id) => renderGridItem({
            date: moment(days?.[0]?.date).subtract(id, 'days'),
            colorId: (10 - 2 + id) % 10,
            beastId: -1,
            beastNumber: -1,
          },
          beast,
          false,
        ))}
      </Grid>
      <AnimatePresence mode="wait">
        <motion.div
          key={`calendar-grid-${year}`}
          initial={{ opacity: 0.2 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.2 }}
          transition={{ duration: 0.5 }}
        >
          {chunks.map((items, index) => (
            <Grid
              item
              container
              key={getGroupKey(index)}
              sx={{
                backgroundColor: '#2a6eca',
                border: '2px solid transparent',
                borderBottomWidth: '4px',
                borderTopWidth: '4px',
              }}
            >
              {items.map((data) => renderGridItem(
                data,
                data.date.year() === year ? data.date.format('DD.MM') : '',
                data.date.year() === year,
              ))}
            </Grid>
          ))}
        </motion.div>
      </AnimatePresence>
    </Grid>
  );
});
