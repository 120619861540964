import * as yup from 'yup';
import {
  forgotPasswordFormFields,
  loginFormFields,
  restorePasswordFormFields,
  signupFormFields,
} from '../../fields/formFields';
import { emailSchema } from '../common/emailSchema';
import '../common/extends';
import { passwordConfirmationSchema, passwordSchema, secretPasswordSchema } from '../common/passwordSchema';

export const loginFormSchema = yup.object({
  [loginFormFields.email]: emailSchema,
  [loginFormFields.password]: secretPasswordSchema,
});

export const signupFormSchema = yup.object({
  [signupFormFields.email]: emailSchema,
  [signupFormFields.name]: yup.string().required('Имя обязательно').trim(),
  [signupFormFields.password]: passwordSchema,
  [signupFormFields.dob]: yup.date().nullable().required('Дата рождения обязательна'),
});

export const forgotPasswordFormSchema = yup.object({
  [forgotPasswordFormFields.email]: emailSchema,
});

export const restorePasswordFormSchema = yup.object({
  [restorePasswordFormFields.password]: passwordSchema,
  [restorePasswordFormFields.passwordConfirmation]: passwordConfirmationSchema,
  [restorePasswordFormFields.token]: yup
    .string()
    .length(6, 'Код должен содержать 6 символов')
    .required('Код обязателен')
    .trim(),
});
