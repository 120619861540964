import React, { useImperativeHandle, useState } from 'react';

export interface AsyncDialogRef<T = any, R = any> {
  show: (data?: T) => Promise<R>;
}

export interface IAsyncDialogProps<T, R = any, D = any> {
  data?: D;
  open: boolean;
  onClose: (value?: R) => void | Promise<void>;
  onOk: (reason?: T) => void | Promise<void>;
}

interface AsyncDialogProps<T = any> {
  dialog: (props: IAsyncDialogProps<T>) => JSX.Element;
}

type PromiseInfo = {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
};

export const AsyncDialog = React.forwardRef<AsyncDialogRef, AsyncDialogProps>((props, ref) => {
  const [promiseInfo, setPromiseInfo] = useState<PromiseInfo | null>(null);
  const [data, setData] = useState<any>(null);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (initialData?: any) =>
      new Promise((resolve, reject) => {
        setPromiseInfo({ resolve, reject });
        setData(initialData);
        setOpen(true);
      }),
  }));

  const onOk = (value?: any) => {
    promiseInfo?.resolve(value);
    setOpen(false);
  };

  const onClose = (reason?: any) => {
    promiseInfo?.reject(reason);
    setOpen(false);
  };

  return <props.dialog {...props} data={data} open={open} onClose={onClose} onOk={onOk} />;
});
