import { ApiResponse } from './api';

export interface ITreeElement {
  beastId: number;
  colorId: number;
  number: number;
}

export enum TreeType {
  Simple = 'Simple',
  Projection = 'Projection',
}

export interface IBoardBaseTree {
  id: string;
  date: string;
  title: string;
  canDelete: boolean;
}

export interface IBoardSimpleTree extends IBoardBaseTree {
  type: TreeType.Simple;
}

export interface IBoardProjectionTree extends IBoardBaseTree {
  type: TreeType.Projection;
  dateNull: string;
  titleNull: string;
}

export type BoardTree = IBoardSimpleTree | IBoardProjectionTree;

export interface ITreeElements {
  date: string;
  title: string;
  head: ITreeElement;
  body: ITreeElement;
  feet: ITreeElement;
  reliquary: ITreeElement;
  topBelt: ITreeElement;
  botBelt: ITreeElement;
  leftTopGuard: ITreeElement;
  leftBotGuard: ITreeElement;
  rightTopGuard: ITreeElement;
  rightBotGuard: ITreeElement;
}

export type CreateBoardTreeRequest = Omit<BoardTree, 'id'>;
export type CreateBoardTreeResponse = ApiResponse<BoardTree>;

export type DeleteBoardTreeResponse = ApiResponse<BoardTree>;

export type UpdateBoardTreePayload = Partial<Omit<IBoardProjectionTree, 'type'>> & Required<Pick<BoardTree, 'id'>>;
export type FetchBoardTreesResponse = ApiResponse<BoardTree[]>;
