import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HotTubIcon from '@mui/icons-material/HotTub';
import ReplayIcon from '@mui/icons-material/Replay';
import { locations } from '../../routes/locations';

export const Error = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(locations.dashboard());
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container flexDirection="column" alignItems="center" justifyContent="center" height="100%">
        <Grid item>
          <HotTubIcon sx={{ fontSize: 80 }} />
        </Grid>
        <Grid item sx={{ mt: 5 }}>
          <Typography component="h3" variant="h3" color="text.primary" textAlign="center">
            Страница не найдена
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 5 }}>
          <Typography component="h6" variant="body2" color="text.secondary" textAlign="center">
            Вы или ввели не верный адрес или перешли по плохой ссылке.
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 10 }}>
          <Button variant="outlined" color="primary" startIcon={<ReplayIcon />} onClick={goHome}>
            На главную
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
