import React, { useContext, createContext, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import useSound from 'use-sound';
import { setCaptureImage } from '../redux/modules/captureImage/captureImage.actions';

interface ICaptureImage {
  capture: (elementId: string, fileName: string) => void;
}

const initialState = {
  // eslint-disable-next-line
  capture: (elementId: string, fileName: string) => {},
};

const captureImageContext = createContext<ICaptureImage>(initialState);

type CaptureImageProviderProps = {
  children: ReactNode;
};

const useProvideCaptureImage = () => {
  const dispatch = useDispatch();
  const [playCamera] = useSound(
    '/sounds/camera.mp3',
    { volume: 0.25 },
  );

  const capture = (elementId: string, fileName: string) => {
    const calendarElement = document.getElementById(elementId);

    if (calendarElement) {
      html2canvas(calendarElement, { allowTaint: true, windowWidth: 1440 })
        .then((canvas) => {
          dispatch(setCaptureImage({ fileName, imageUrl: canvas.toDataURL() }));
          playCamera();
        });
    }
  };

  return {
    capture,
  };
};

export const CaptureImageProvider: React.FC<CaptureImageProviderProps> = ({ children }) => (
  <captureImageContext.Provider value={useProvideCaptureImage()}>
    {children}
  </captureImageContext.Provider>
);

export const useCaptureImage = () => useContext(captureImageContext);
