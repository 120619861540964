import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { forgotPasswordFormFields, restorePasswordFormFields } from '../../forms/fields/formFields';
import { forgotPasswordFormSchema, restorePasswordFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { forgotPassword, restorePassword } from '../../redux/modules/auth/auth.actions';
import { authSelector } from '../../redux/modules/auth/auth.selectors';
import { locations } from '../../routes/locations';

export function ForgotPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [restore, setRestore] = useState(false);
  const { restorePasswordStatus, forgotPasswordStatus, error } = useSelector(authSelector);
  const loading = forgotPasswordStatus === fetchStatuses.pending || restorePasswordStatus === fetchStatuses.pending;
  const forgotPasswordForm = useFormik({
    initialValues: { [forgotPasswordFormFields.email]: '' },
    validationSchema: forgotPasswordFormSchema,
    onSubmit: (formValues) => {
      dispatch(
        forgotPassword({
          email: formValues.email.trim(),
        }),
      );
    },
  });
  const restorePasswordForm = useFormik({
    initialValues: {
      [restorePasswordFormFields.password]: '',
      [restorePasswordFormFields.passwordConfirmation]: '',
      [restorePasswordFormFields.token]: '',
    },
    validationSchema: restorePasswordFormSchema,
    onSubmit: (formValues) => {
      dispatch(
        restorePassword({
          ...formValues,
          token: formValues.token.trim(),
        }),
      );
    },
  });

  useEffect(() => {
    if (forgotPasswordStatus === fetchStatuses.success) {
      setRestore(true);
    }
    if (restorePasswordStatus === fetchStatuses.success) {
      navigate(locations.login());
    }
  }, [forgotPasswordStatus, restorePasswordStatus]);

  const alreadyHaveToken = () => {
    restorePasswordForm.resetForm();
    setRestore(true);
  };

  const tryAnotherEmail = () => {
    forgotPasswordForm.resetForm();
    setRestore(false);
  };

  const backToLogin = () => {
    navigate(locations.login(), { replace: true });
  };

  const renderForgotPasswordForm = () => (
    <form style={{ width: '100%' }} onSubmit={forgotPasswordForm.handleSubmit}>
      <TextField
        id="email"
        fullWidth
        name={forgotPasswordFormFields.email}
        label="Email"
        value={forgotPasswordForm.values[forgotPasswordFormFields.email]}
        onChange={forgotPasswordForm.handleChange}
        error={forgotPasswordForm.touched.email && !!forgotPasswordForm.errors.email}
        helperText={forgotPasswordForm.touched.email && forgotPasswordForm.errors.email}
        autoComplete="username"
        margin="normal"
        required
        autoFocus
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      <LoadingButton
        type="submit"
        color="primary"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={<LoginIcon />}
        loading={loading}
        loadingPosition="end"
      >
        Отправить
      </LoadingButton>
      <Grid container flexDirection="column">
        <Grid item>
          <Link variant="body2" onClick={alreadyHaveToken} sx={{ cursor: 'pointer' }}>
            Уже есть код
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" onClick={backToLogin} sx={{ cursor: 'pointer' }}>
            Вернутся ко входу
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  const renderRestorePasswordForm = () => (
    <form style={{ width: '100%' }} onSubmit={restorePasswordForm.handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        name={restorePasswordFormFields.password}
        label="Пароль"
        type="password"
        value={restorePasswordForm.values[restorePasswordFormFields.password]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          restorePasswordForm.handleChange({
            target: {
              name: restorePasswordFormFields.password,
              type: 'password',
              value: event.target.value.replace(/\s/g, ''),
            },
          });
        }}
        error={restorePasswordForm.touched.password && !!restorePasswordForm.errors.password}
        helperText={restorePasswordForm.touched.password && restorePasswordForm.errors.password}
        autoComplete="new-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="password-confirmation"
        name={restorePasswordFormFields.passwordConfirmation}
        label="Повторить пароль"
        type="password"
        value={restorePasswordForm.values[restorePasswordFormFields.passwordConfirmation]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          restorePasswordForm.handleChange({
            target: {
              name: restorePasswordFormFields.passwordConfirmation,
              type: 'password',
              value: event.target.value.replace(/\s/g, ''),
            },
          });
        }}
        error={restorePasswordForm.touched.passwordConfirmation && !!restorePasswordForm.errors.passwordConfirmation}
        helperText={restorePasswordForm.touched.passwordConfirmation && restorePasswordForm.errors.passwordConfirmation}
        autoComplete="new-password-confirmation"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="token"
        name={restorePasswordFormFields.token}
        label="Код из email"
        type="token"
        value={restorePasswordForm.values[restorePasswordFormFields.token]}
        onChange={restorePasswordForm.handleChange}
        error={restorePasswordForm.touched.token && !!restorePasswordForm.errors.token}
        helperText={restorePasswordForm.touched.token && restorePasswordForm.errors.token}
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      <LoadingButton
        type="submit"
        color="primary"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={<LoginIcon />}
        loading={loading}
        loadingPosition="end"
      >
        Отправить
      </LoadingButton>
      <Grid container flexDirection="column">
        <Grid item>
          <Link variant="body2" onClick={tryAnotherEmail} sx={{ cursor: 'pointer' }}>
            Изменить email
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" onClick={backToLogin} sx={{ cursor: 'pointer' }}>
            Вернутся ко входу
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: 'calc(100vh - 64px - 140px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component={motion.div}
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.3 }}
      key={restore ? 'restore' : 'forgot'}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: 4,
          paddingY: 8,
          borderRadius: 5,
        }}
        component={Paper}
      >
        <Grid item>
          <Typography component="h1" variant="h5">
            {restore ? 'Восстановить пароль' : 'Не помню пароль'}
          </Typography>
        </Grid>

        <Grid item>
          {restore ? renderRestorePasswordForm() : renderForgotPasswordForm()}
        </Grid>
      </Grid>
    </Container>
  );
}
