import moment from 'moment';
import React, { useState } from 'react';
import { Brush, CartesianGrid, Line, Tooltip, LineChart, ResponsiveContainer, XAxis, YAxis, Legend } from 'recharts';

export interface ChartDataSetItem {
  date: string;
  physical: number;
  emotional: number;
  intellectual: number;
}

interface BiorhythmsChartProps {
  data: ChartDataSetItem[];
}

const dataKeyToLabel = {
  physical: 'Физ.',
  emotional: 'Эмоц.',
  intellectual: 'Интел.',
};

export const BiorhythmsChart: React.FC<BiorhythmsChartProps> = (props) => {
  const { data } = props;

  const [brushState, setBrushState] = useState({ startIndex: 0, endIndex: 30 });

  const handleUpdateBrush = ({ startIndex, endIndex }: { startIndex?: number, endIndex?: number }) => {
    const newStartIndex = (startIndex || 0);
    let newEndIndex = (endIndex || 0);

    if (newEndIndex - newStartIndex > 30) {
      newEndIndex = newStartIndex + 30;
    }

    setBrushState({ startIndex: newStartIndex, endIndex: newEndIndex });
  };

  const formatterTooltip = (value, name, otherProps) => [`${value}%`, dataKeyToLabel[name], otherProps];

  const formatterLegend = (value) => dataKeyToLabel[value];
  const tickFormatterBrush = (val) => moment(data[val].date, 'DD.MM.YY').format('DD.MM');
  const tickFormatterYAxis = (val) => `${val}%`;

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        data={data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 45,
          left: -15,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" angle={-80} allowDataOverflow={false} textAnchor="end" height={90} />
        <YAxis tickFormatter={tickFormatterYAxis} />
        <Tooltip contentStyle={{ backgroundColor: 'lightgray' }} formatter={formatterTooltip} />
        <Line type="monotone" dataKey="physical" stroke="red" fill="red" label="Физический" />
        <Line type="monotone" dataKey="emotional" stroke="green" fill="green" label="Эмоциональный" />
        <Line type="monotone" dataKey="intellectual" stroke="blue" fill="aqua" label="Интеллектуальный" />
        <Legend verticalAlign="top" height={36} formatter={formatterLegend} />
        <Brush
          startIndex={brushState.startIndex}
          endIndex={brushState.endIndex}
          fill="rgba(0, 0, 0, 0)"
          stroke="blue"
          onChange={handleUpdateBrush}
          tickFormatter={tickFormatterBrush}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
