import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/system/Container';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { DaysInfoModal } from '../../components/DaysInfoModal';
import { TreeController } from '../../components/TreeController';
import { TreeQuickLinks } from '../../components/TreeQuickLinks';
import { useAppDispatch } from '../../redux/hooks';
import { fetchDaysInfo } from '../../redux/modules/config/config.actions';
import { treeSelector } from '../../redux/modules/tree/tree.selectors';
import { TreeType } from '../../types/tree';
import { container, item } from '../../utils/animation';
import HomeSpeedDial from './HomeSpeadDeal';

import './home.css';

export const Home = () => {
  const dispatch = useAppDispatch();
  const [openDaysModal, setOpenDaysModal] = useState(false);

  const { boardTrees } = useSelector(treeSelector);

  useEffect(() => {
    dispatch(fetchDaysInfo());
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <TreeQuickLinks />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 5 }}
        component={motion.div}
        initial={{ y: '-150%' }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Grid item>
          <Typography variant="h4">Рабочее пространство</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<CalendarTodayIcon />}
            onClick={() => setOpenDaysModal(true)}
          >
            Информация на сегодня
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        container
        id="board-threes"
        spacing={2}
        flexDirection="row"
        component={motion.div}
        variants={container}
        initial="hidden"
        animate="show"
      >
        {boardTrees.map((bt) => (
          <Grid
            item
            component={motion.div}
            variants={item}
            id={`board-tree-${bt.id}`}
            key={bt.id}
            sm={bt.type === TreeType.Projection ? 12 : 6}
            md={bt.type === TreeType.Projection ? 8 : 4}
          >
            <TreeController tree={bt} />
          </Grid>
        ))}
      </Grid>

      <DaysInfoModal open={openDaysModal} handleClose={() => setOpenDaysModal(false)} />

      <HomeSpeedDial bottom={50} />
    </Container>
  );
};
